<template>
  <div class="container">
    <modals-container v-on:update="updated" />
    <v-dialog />

    <form v-on:submit.prevent>
      <div class="row">
        <div class="col-xs-4">
          <label for="email"
            >Account Name
            <input
              type="text"
              @input="updateAccountName"
              :value="account.name"
            />
          </label>
        </div>
      </div>

      <div class="row center-xs">
        <div class="col-xs-4">
          <v-button :event="updateAccount" :event-data="account.id"
            >Update Account</v-button
          >
        </div>
      </div>
    </form>

    <div class="row">
      <div class="col-xs">AdReady ID: {{ account.id }}</div>

      <div class="col-xs">Created At: {{ account.created_at }}</div>
      <div class="col-xs">Updated At: {{ account.updated_at }}</div>
    </div>
    <div class="row">
      <div class="col-xs-3">
        <v-button :event="showModal">Add Report URL</v-button>
      </div>
    </div>
    <div class="row center-xs">
      <div
        class="col-xs-3 card"
        v-for="report in account.reports"
        :key="report.id"
      >
        <div class="card-container">
          <div class="row">
            <div class="col-xs">
              {{ report.type }}
            </div>
            <div class="col-xs-5">
              <div class="row between-xs">
                <font-awesome-icon
                  :icon="['fas', 'chart-pie']"
                ></font-awesome-icon>
                <font-awesome-icon
                  :icon="['fal', 'pencil']"
                  @click="showModal(report.id)"
                ></font-awesome-icon>
                <font-awesome-icon
                  :icon="['fal', 'times']"
                  @click="confirmReportDelete(report.id)"
                ></font-awesome-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-4">
        <v-button :event="confirmAccountDelete" v-if="this.deleted === null"
          >Delete Account</v-button
        >
        <v-button :event="confirmAccountRestore" v-if="this.deleted !== null"
          >Restore Account</v-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import ReportEditModal from "./ReportEditModal";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return { selected: null, account_id: null };
  },
  computed: {
    ...mapState({ accounts: state => state.adready_plus.accounts }),
    account() {
      return this.accounts.length > 0 ? this.accounts[0] : {};
    },
    deleted() {
      return this.account.deletedAt != null ? "deleted" : null;
    }
  },
  created() {
    this.account_id = this.$route.params.id;
  },
  async mounted() {
    await this.getAccount(this.account_id);
  },
  methods: {
    ...mapActions({
      getAccount: "adready_plus/getAccount",
      updateAccount: "adready_plus/updateAccount",
      deleteAccount: "adready_plus/deleteAccount",
      deleteReport: "adready_plus/deleteReport"
    }),
    async updated() {
      await this.getAccount(this.account.id);
      this.$modal.hideAll();
    },

    updateAccountName(e) {
      this.$store.commit("adready_plus/SET_ACCOUNTS_PROPS", {
        index: 0,
        key: "name",
        value: e.target.value
      });
    },

    setSelected({ id, name }) {
      this.selected = { external_account_id: id, name };
    },
    showModal(id = null) {
      let report = this.account.reports.filter(report => report.id === id);
      if (report.length === 0) {
        report = {};
      } else {
        report = report[0];
      }

      this.$modal.show(
        ReportEditModal,
        { report: report, account_id: this.account.id },
        { height: "400px" }
      );
    },
    confirmReportDelete(report_id) {
      this.showConfirmDialog(async () => {
        await this.deleteReport(report_id);
        this.updated();
        this.$modal.hide("dialog");
      }, "Are you sure you want to delete this report?");
    },
    confirmAccountDelete() {
      this.showConfirmDialog(async () => {
        await this.deleteAccount(this.id);
        this.updated();
        this.$modal.hide("dialog");
      }, "Are you sure you want to delete this account?");
    },
    confirmAccountRestore() {
      this.showConfirmDialog(async () => {
        await this.updateAccount(
          { active: true, deletedAt: null },
          this.account_id
        );
        this.updated();
        this.$modal.hide("dialog");
      }, "Are you sure you want to restore this account?");
    },
    showConfirmDialog(handler, text) {
      this.$modal.show("dialog", {
        title: "Alert!",
        text,
        buttons: [
          {
            title: "Confirm",
            handler
          },
          {
            title: "Close"
          }
        ]
      });
    }
  }
};
</script>
