<template>
  <div class="pagination-wrap">
    <div class="pagination-text">
      Page {{ pagination.current_page }} Records {{ pagination.from }} -
      {{ pagination.to }} of {{ pagination.total }}
    </div>

    <ul class="row center-xs pagination-links">
      <li :class="{ disabled: pagination.current_page === 1 }">
        <font-awesome-icon
          :icon="['far', 'arrow-left']"
          @click="pageClicked(pagination.current_page - 1)"
        ></font-awesome-icon>
      </li>
      <li
        class="col-xs pagination-item"
        v-for="(page, index) in pageLinks"
        :key="index"
        @click="pageClicked(page)"
        :class="{ active: isActive(page), disabled: page === '...' }"
      >{{ page }}</li>
      <li :class="{
          disabled: pagination.current_page === pagination.num_pages
        }">
        <font-awesome-icon
          :icon="['far', 'arrow-right']"
          @click="pageClicked(pagination.current_page + 1)"
        ></font-awesome-icon>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      range: 5
    };
  },

  computed: {
    pageLinks() {
      if (this.pagination.num_pages === undefined) {
        return [];
      }

      const arr = [];
      let preDots = false;
      let postDots = false;

      for (let page = 1; page <= this.pagination.num_pages; page++) {
        if (this.pagination.num_pages <= 10) {
          arr.push(page);
        } else {
          if (page === 1) {
            arr.push(page);
          } else if (page === this.pagination.num_pages) {
            arr.push(page);
          } else if (
            // link is within this.range of current
            (page > this.pagination.current_page - this.range &&
              page < this.pagination.current_page + this.range) ||
            // current and link less than this.range
            (page < this.range && this.pagination.current_page < this.range) ||
            // current and link within this.range of end
            (page > this.pagination.num_pages - this.range &&
              this.pagination.current_page >
                this.pagination.num_pages - this.range)
          ) {
            arr.push(page);
          } else if (page < this.pagination.current_page && !preDots) {
            arr.push("...");
            preDots = true;
            page;
          } else if (page > this.pagination.current_page && !postDots) {
            arr.push("...");
            postDots = true;
          }
        }
      }
      return arr;
    },

    shouldShowPagination() {
      if (this.pagination.num_pages === undefined) {
        return false;
      }
      if (this.pagination.total === 0) {
        return false;
      }
      return this.pagination.num_pages > 1;
    }
  },
  methods: {
    isActive(page) {
      const currentPage = this.pagination.current_page || 1;
      return currentPage === page;
    },
    pageClicked(page) {
      if (
        page === "..." ||
        page === this.pagination.current_page ||
        page > this.pagination.num_pages ||
        page < 1
      ) {
        return;
      }
      this.$emit("pageChange", page);
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination-wrap {
  margin: 30px 0 0;
  padding: 0;

  .pagination-text {
    padding: 0 0 10px;
  }

  .pagination-links {
    padding: 0;
    margin: 0;
    border-top: 1px solid $grey_8;
    border-bottom: 1px solid $grey_8;
    border-left: 1px solid $grey_8;
    li {
      list-style: none;
      color: $grey_0;
      cursor: pointer;
      padding: 6px 12px;
      border-right: 1px solid $grey_8;
      transition: all 0.3s ease-in-out;

      &.active {
        background-color: $primary;
      }
      &.disabled {
        cursor: default;
      }
      &:hover {
        color: $grey_0;
        background-color: $primary;
      }
    }
  }
}
</style>
