import axios from "axios";

const state = {
  accounts: [],
  reports: [],
  pagination: {}
};

const getters = {};

const mutations = {
  SET_ACCOUNTS(state, accounts) {
    state.accounts = accounts;
  },
  SET_ACCOUNTS_PROPS(state, { index, key, value }) {
    state.accounts[index][key] = value;
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  }
};
const actions = {
  async getAccounts({ commit }, params) {
    await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/plus/accounts`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      },
      params
    })
      .then(response => {
        commit("SET_ACCOUNTS", response.data.data);
        let pagination = response.data;
        delete pagination.data;
        commit("SET_PAGINATION", pagination);
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },
  async getAccount({ commit }, account_id) {
    await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/plus/account/${account_id}`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(response => {
        commit("SET_ACCOUNTS", [response.data]);
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },
  async createAccount({ commit }, { name, external_account_id }) {
    let data = {
      name,
      external_account_id
    };

    await axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/plus/account`,
      headers: { Authorization: `Bearer ${window.Token}` },
      data
    })
      .then(response => {
        commit("SET_ACCOUNTS", [response.data]);
        commit("SET_MESSAGES", ["Account has been created"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },
  async updateAccount({ commit, state }, account_id) {
    await axios({
      method: "put",
      url: `${process.env.VUE_APP_API_URL}/plus/account/${account_id}`,
      headers: { Authorization: `Bearer ${window.Token}` },
      data: { name: state.accounts[0].name }
    })
      .then(() => {
        commit("SET_MESSAGES", ["Account has been updated"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },
  async deleteAccount({ commit }, id) {
    let account_id = id === null ? this.id : id;
    await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API_URL}/plus/account/${account_id}`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(() => {
        commit("SET_MESSAGES", ["Account deleted"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },
  async createReport({ commit }, data) {
    await axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/plus/report`,
      headers: { Authorization: `Bearer ${window.Token}` },
      data
    })
      .then(() => {
        commit("SET_MESSAGES", ["Report Created"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },
  async deleteReport({ commit }, id) {
    await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API_URL}/plus/report/${id}`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(() => {
        commit("SET_MESSAGES", ["Report Deleted"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },
  async updateReport({ commit }, { report_id, data }) {
    await axios({
      method: "put",
      url: `${process.env.VUE_APP_API_URL}/plus/report/${report_id}`,
      headers: { Authorization: `Bearer ${window.Token}` },
      data
    })
      .then(() => {
        commit("SET_MESSAGES", ["Report Updated"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
