<template>
  <div
    class="loading-cube-wrap row center-xs"
    v-if="showLoading"
    :class="{ 'small-loader': smallLoader }"
  >
    <div class="row center-xs loading-cube-grid" v-if="!smallLoader">
      <div class="loading-cube loading-cube1"></div>
      <div class="loading-cube loading-cube2"></div>
      <div class="loading-cube loading-cube3"></div>
      <div class="loading-cube loading-cube4"></div>
      <div class="loading-cube loading-cube5"></div>
      <div class="loading-cube loading-cube6"></div>
      <div class="loading-cube loading-cube7"></div>
      <div class="loading-cube loading-cube8"></div>
      <div class="loading-cube loading-cube9"></div>
    </div>
    <div class="row center-xs loading-cube-grid" v-else>
      <div class="loading-cube loading-cube1"></div>
      <div class="loading-cube loading-cube2"></div>
      <div class="loading-cube loading-cube3"></div>
    </div>
    <div class="loader-txt" v-if="loadingText">{{ loadingText }}</div>
  </div>
</template>

<script>
export default {
  props: {
    showLoading: {
      require: false,
      type: Boolean,
      default: true
    },
    loadingText: {
      require: false,
      type: String,
      default: ""
    },
    smallLoader: {
      require: false,
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.loading-cube-wrap {
  width: 100px;
  height: 100px;
  z-index: 9999;

  &.small-loader {
    height: 20px;
  }

  .loader-txt {
    margin: 20px 0 0;
    animation: pulse 1s infinite;
    text-align: center;
    font-size: 14px;
  }
}
.loading-cube-grid {
  margin: 0 auto;
  width: 60px;

  .loading-cube {
    padding: 5px 9px;
    -webkit-animation: cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: cubeGridScaleDelay 1.3s infinite ease-in-out;
    background-color: $primary;
  }

  .loading-cube1 {
    animation-delay: 0.2s;
  }
  .loading-cube2 {
    animation-delay: 0.3s;
  }
  .loading-cube3 {
    animation-delay: 0.4s;
  }
  .loading-cube4 {
    animation-delay: 0.1s;
  }
  .loading-cube5 {
    animation-delay: 0.2s;
  }
  .loading-cube6 {
    animation-delay: 0.3s;
  }
  .loading-cube7 {
    animation-delay: 0s;
  }
  .loading-cube8 {
    animation-delay: 0.1s;
  }
  .loading-cube9 {
    animation-delay: 0.2s;
  }
}
@-webkit-keyframes cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
@-moz-keyframes cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -moz-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -moz-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes pulse {
  0% {
    color: $grey_8;
  }
  50% {
    color: $grey_0;
  }
  100% {
    color: $grey_8;
  }
}
</style>
