<template>
  <div class="landing-page">
    <TitleBar title="Tech Portal" image="/assets/img/auth.png" />

    <div class="row center-xs between-xs">
      <a class="row middle-xs landing-card" href="/auth/users">
        <font-awesome-icon :icon="['fal', 'users']" size="3x"></font-awesome-icon>
        <span>Users</span>
      </a>
      <a class="row middle-xs landing-card" href="/auth/accounts">
        <font-awesome-icon :icon="['fas', 'clone']" size="3x"></font-awesome-icon>
        <span>Accounts</span>
      </a>
      <a class="row middle-xs landing-card" href="/auth/roles">
        <font-awesome-icon :icon="['fas', 'ballot-check']" size="3x"></font-awesome-icon>
        <span>Roles</span>
      </a>
      <a class="row middle-xs landing-card" href="/auth/groups">
        <font-awesome-icon :icon="['fas', 'boxes-alt']" size="3x"></font-awesome-icon>
        <span>Groups</span>
      </a>
    </div>
  </div>
</template>

<script>
import TitleBar from "@/components/elements/TitleBar";
export default {
  components: {
    TitleBar
  }
};
</script>
