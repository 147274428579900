import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {};
  },

  computed: {
    ...mapState({
      accounts: state => state.auth_account.accounts,
      roles: state => state.auth_role.roles,
      groups: state => state.auth_group.groups
    })
  },
  async mounted() {
    await this.getAccounts();
    await this.getRoles();
    await this.getGroups();
  },
  methods: {
    ...mapActions({
      getAccounts: "auth_account/getAccounts",
      getRoles: "auth_role/getRoles",
      getGroups: "auth_group/getGroups"
    })
  }
};
