<template>
  <div class="inner-content">
    <modals-container />

    <div class="row middle-xs between-xs">
      <span class="sub-title">AdReady+ List</span>
      <a href="/adready-plus/accounts/create" class="button solid btn-5">Create Account</a>
    </div>

    <div class="row middle-xs between-xs sub-header">
      <label for="search" class="search-bar col-xs-9">
        Search:
        <input type="text" v-model="query_params.query" />
      </label>
    </div>

    <div class="row center-xs">
      <Loading :show-loading="showLoading" loading-text="Loading data..." />

      <table v-if="!showLoading">
        <tr>
          <th>Name</th>
          <th class="actions">Actions</th>
        </tr>
        <tr v-for="account in accounts" :key="account.id">
          <td>{{ account.name }}</td>
          <td>
            <div class="row table-actions">
              <div class="col-xs">
                <a :href="`/adready-plus/accounts/${account.id}`">
                  <font-awesome-icon :icon="['fas', 'user']"></font-awesome-icon>
                </a>
              </div>
              <div class="col-xs">
                <a :href="`/adready-plus/accounts/${account.id}/edit`">
                  <font-awesome-icon :icon="['fas', 'pencil']"></font-awesome-icon>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <Pagination
        v-if="pagination.num_pages > 1"
        @pageChange="updatePage"
        :pagination="pagination"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Pagination from "@/components/Pagination";
import Loading from "@/components/elements/Loading";

export default {
  data() {
    return {
      query_params: {
        page: 1,
        per_page: 20,
        query: null,
        sort: "id|asc"
      },
      showLoading: false
    };
  },
  components: { Pagination, Loading },
  watch: {
    query_params: {
      async handler() {
        await this.getAccounts(this.query_params);
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      accounts: state => state.adready_plus.accounts,
      pagination: state => state.adready_plus.pagination
    })
  },
  async mounted() {
    this.showLoading = true;
    await this.getAccounts(this.query_params).then(() => {
      this.showLoading = false;
    });
  },
  methods: {
    ...mapActions({
      getAccounts: "adready_plus/getAccounts"
    }),
    updatePage(page) {
      return (this.query_params.page = page);
    }
  }
};
</script>
<style lang="scss" scoped>
.actions {
  width: 160px;
}
</style>
