<template>
  <div id="app" class="bg-image">
    <modals-container />
    <notifications />

    <div class="app-header main row between-xs top-bar" v-if="isAuthenticated" id="header">
      <div class="col-xs p0">
        <a href="/">
          <img :src="`/assets/img/logo-small.webp`" class="logo" />
        </a>
      </div>
      <div class="col-xs">
        <div class="nav-right">
          <ul class="row end-xs nav-icons middle-xs">
            <li>
              <a href="/profile">Profile</a>
            </li>
            <li>
              <a href="/logout">
                <font-awesome-icon :icon="['fal', 'sign-out']"></font-awesome-icon>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="router-view">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  computed: {
    ...mapState({
      token: state => state.authentication.token
    }),
    ...mapGetters({
      isAuthenticated: "authentication/isAuthenticated"
    })
  },

  created() {
    const token = localStorage.getItem("token");

    if (token !== null) {
      this.$store.dispatch("authentication/decodeToken", token).then(() => {});
    }
  },
  mounted() {
    const $header = document.getElementById("header");
    window.onscroll = () => {
      let bottomBarPosition = $header.offsetTop;

      if (window.pageYOffset > bottomBarPosition) {
        $header.classList.add("fixed");
      } else {
        $header.classList.remove("fixed");
      }
    };
  },
  methods: {}
};
</script>

<style lang="scss">
.loading-cube {
}
</style>
