<template>
  <div class="container row middle-xs">
    <div class="landing-page">
      <TitleBar title="Tech Portal" image="/assets/img/digital-remedy.png" />

      <div class="row center-xs section-nav">
        <Section :access="canAccessAuth" href="/auth" image="/assets/img/auth.png" />
        <Section :access="canAccessFlip" href="/flip" image="/assets/img/flip-a.png" />
        <!-- <Section :access="canAccessTPC" href="/tpc" image="/assets/img/tpc.png" /> -->
        <Section :access="canAccessPlus" href="/adready-plus" image="/assets/img/adready-plus.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TitleBar from "@/components/elements/TitleBar";
import Section from "@/components/elements/Section";

export default {
  components: {
    Section,
    TitleBar
  },
  computed: {
    ...mapGetters({
      canAccessAuth: "authentication/canAccessAuth",
      canAccessFlip: "authentication/canAccessFlip",
      canAccessTPC: "authentication/canAccessTPC",
      canAccessPlus: "authentication/canAccessPlus"
    })
  }
};
</script>
