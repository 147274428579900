<template>
  <div class="container row">
    <a :href="link_url" class="route-history icon-back">
      <font-awesome-icon :icon="['far', 'arrow-left']"></font-awesome-icon>
      {{ link_text }}
    </a>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      is_landing: false
    };
  },
  computed: {
    route_name() {
      let route = this.$route.path.split("/");
      return route.length > 3 ? route[1] + "/" + route[2] : route[1];
    },
    link_text() {
      return this.is_landing
        ? "Home"
        : `Back To ${this.jsUcfirst(
            this.route_name.replace(/_|-|\//g, " ")
          )} Service Home`;
    },
    link_url() {
      return this.is_landing ? "/" : `/${this.route_name}`;
    }
  },
  created() {
    this.is_landing = this.$route.path.split("/").length === 2;
  },
  methods: {
    jsUcfirst(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.route-history {
  padding: 0;
  margin: 0 0 30px;
  color: $text_color;

  svg {
    margin: 0 5px 0 0;
    color: $primary;
  }
}
</style>
