<template>
  <div class="inner-content">
    <modals-container v-on:update="updated" />
    <v-dialog />

    <span class="sub-title">Edit Role</span>

    <form v-on:submit.prevent class="edit-form">
      <div class="row start-xs form-row info-row">
        <span class="col-xs-3">AdReady ID: {{ role.id }}</span>
        <span class="col-xs-3">
          Status:
          <b :class="status">{{ statusText }}</b>
        </span>
        <span class="col-xs-3">Created At: {{ formatAuthDate(role.createdAt) }}</span>
        <span class="col-xs-3">Updated At: {{ formatAuthDate(role.updatedAt) }}</span>
      </div>

      <div class="row form-row">
        <div class="col-xs">
          <label for="email">
            Role Name
            <input type="text" v-model="role_form.title" />
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs">
          <v-button :event="updateRoleAction">Update Role</v-button>
        </div>
      </div>
    </form>

    <div class="row center-xs">
      <div class="col-xs">
        <v-button :event="confirmRoleDelete" v-if="this.deleted === null">Delete Role</v-button>
        <v-button :event="confirmRoleRestore" v-if="this.deleted !== null">Restore Role</v-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return { role_id: null, role_form: { title: null, active: false } };
  },
  computed: {
    ...mapState({
      roles: state => state.auth_role.roles
    }),
    role() {
      return this.roles.length > 0 ? this.roles[0] : { userInfo: {} };
    },
    statusText() {
      return this.status.charAt(0).toUpperCase() + this.status.slice(1);
    },
    deleted() {
      return this.role.deletedAt != null ? "deleted" : null;
    },
    status() {
      if (this.role.deletedAt != null) {
        return "deleted";
      }
      return this.role.active ? "active" : "deleted";
    }
  },
  created() {
    this.role_id = this.$route.params.id;
  },
  async mounted() {
    await this.getRole(this.role_id);
    this.setRoleFormProps();
  },

  methods: {
    ...mapActions({
      getRole: "auth_role/getRole",
      updateRole: "auth_role/updateRole",
      deleteRole: "auth_role/deleteRole"
    }),
    setRoleFormProps() {
      this.role_form = {
        title: this.role.title,
        active: this.role.active
      };
    },
    async updateRoleAction(params) {
      let updateParams =
        Object.keys(params).length > 0 ? params : this.role_form;

      await this.updateRole({
        ...updateParams,
        id: this.role_id
      });
      await this.updated();
    },
    async updated() {
      await this.getRole(this.role_id);
      this.setRoleFormProps();
    },
    confirmRoleDelete() {
      this.showConfirmDialog(async () => {
        await this.deleteRole(this.role_id);
        this.updated();
        this.$modal.hide("dialog");
      }, "Are you sure you want to delete this role?");
    },
    confirmRoleRestore() {
      this.showConfirmDialog(async () => {
        await this.updateRole({
          id: this.role_id,
          active: true,
          deletedAt: null
        });
        this.updated();
        this.$modal.hide("dialog");
      }, "Are you sure you want to restore this role?");
    },
    showConfirmDialog(handler, text) {
      this.$modal.show("dialog", {
        title: "Alert!",
        text,
        buttons: [
          {
            title: "Confirm",
            handler
          },
          {
            title: "Close"
          }
        ]
      });
    }
  }
};
</script>
