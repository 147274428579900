<template>
  <div class="inner-content">
    <modals-container v-on:update="updated" />
    <v-dialog />

    <span class="sub-title">Edit Group</span>

    <form v-on:submit.prevent class="edit-form">
      <div class="row start-xs form-row info-row">
        <span class="col-xs-3">AdReady ID: {{ group.id }}</span>
        <span class="col-xs-3">
          Status:
          <b :class="status">{{ statusText }}</b>
        </span>
        <span class="col-xs-3">Created At: {{ formatAuthDate(group.createdAt) }}</span>
        <span class="col-xs-3">Updated At: {{ formatAuthDate(group.updatedAt) }}</span>
      </div>

      <div class="row form-row">
        <div class="col-xs">
          <label for="email">
            Group Name
            <input type="text" v-model="group_form.title" />
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-xs">
          <v-button :event="updateGroupAction">Update Group</v-button>
        </div>
      </div>
    </form>

    <div class="row center-xs">
      <div class="col-xs">
        <v-button :event="confirmGroupDelete" v-if="this.deleted === null">Delete Group</v-button>
        <v-button :event="confirmGroupRestore" v-if="this.deleted !== null">Restore Group</v-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return { group_id: null, group_form: { title: null, active: false } };
  },
  computed: {
    ...mapState({
      groups: state => state.auth_group.groups
    }),
    group() {
      return this.groups.length > 0 ? this.groups[0] : { userInfo: {} };
    },
    statusText() {
      return this.status.charAt(0).toUpperCase() + this.status.slice(1);
    },
    deleted() {
      return this.group.deletedAt != null ? "deleted" : null;
    },
    status() {
      if (this.group.deletedAt != null) {
        return "deleted";
      }
      return this.group.active ? "active" : "deleted";
    }
  },
  created() {
    this.group_id = this.$route.params.id;
  },
  async mounted() {
    await this.getGroup(this.group_id);
    this.setGroupFormProps();
  },

  methods: {
    ...mapActions({
      getGroup: "auth_group/getGroup",
      updateGroup: "auth_group/updateGroup",
      deleteGroup: "auth_group/deleteGroup"
    }),
    setGroupFormProps() {
      this.group_form = {
        title: this.group.title,
        active: this.group.active
      };
    },
    async updateGroupAction(params) {
      let updateParams =
        Object.keys(params).length > 0 ? params : this.group_form;

      await this.updateGroup({
        ...updateParams,
        id: this.group_id
      });
      await this.updated();
    },
    async updated() {
      await this.getGroup(this.group_id);
      this.setGroupFormProps();
    },
    confirmGroupDelete() {
      this.showConfirmDialog(async () => {
        await this.deleteGroup(this.group_id);
        this.updated();
        this.$modal.hide("dialog");
      }, "Are you sure you want to delete this group?");
    },
    confirmGroupRestore() {
      this.showConfirmDialog(async () => {
        await this.updateGroup({
          id: this.group_id,
          active: true,
          deletedAt: null
        });
        this.updated();
        this.$modal.hide("dialog");
      }, "Are you sure you want to restore this group?");
    },
    showConfirmDialog(handler, text) {
      this.$modal.show("dialog", {
        title: "Alert!",
        text,
        buttons: [
          {
            title: "Confirm",
            handler
          },
          {
            title: "Close"
          }
        ]
      });
    }
  }
};
</script>
