<template>
  <div class="inner-content">
    <div class="row middle-xs between-xs">
      <span class="sub-title">Create Flip Advertiser</span>
    </div>

    <form v-on:submit.prevent class="edit-form">
      <div class="row form-row">
        <div class="col-xs">
          <label for>
            Xandr Name
            <input type="text" v-model="advertiser.apn_name" />
          </label>
        </div>
        <div class="col-xs">
          <label for>
            Pixel Name
            <input
              ref="pixel_name"
              :class="{ err: pixelNameError }"
              type="text"
              v-model="advertiser.name"
            />
            <span class="info">
              Allowed characters:
              <span class="chars">A-Z a-z 0-9 _</span> (alphanumeric and
              underscore only)
            </span>
          </label>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-xs">
          <label for>
            Xandr ID
            <input type="text" v-model="advertiser.apn_adv_id" />
          </label>
        </div>
        <div class="col-xs">
          <label for>
            Conversion Type:
            <v-select
              :options="options"
              :reduce="option => option.code"
              v-model="advertiser.conversion_type"
            ></v-select>
          </label>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-xs">
          <label for="order_number_regex">
            Order Number Regex:
            <input
              type="text"
              name="order_number_regex"
              v-model="advertiser.order_number_regex"
            />
          </label>
        </div>
        <div class="col-xs">
          <label for="total_regex">
            Total Regex:
            <input
              type="text"
              name="total_regex"
              v-model="advertiser.total_regex"
            />
          </label>
        </div>
      </div>
      <div class="row middle-xs form-row">
        <div class="col-xs">
          <label for="external_file">
            External File:
            <input
              type="text"
              name="external_file"
              v-model="advertiser.external_file"
            />
          </label>
        </div>
        <div class="col-xs">
          <div class="row">
            <div class="col-xs">
              <label>
                Requires Order Number:
                <input
                  type="checkbox"
                  v-model="advertiser.requires_order_number"
                />
              </label>
            </div>
            <div class="col-xs">
              <label>
                Is MNI White-label:
                <input type="checkbox" v-model="advertiser.is_mni" />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row form-row">
        <div class="col-xs">
          <div class="row bottom-xs">
            <div class="col-xs-9 p0">
              <label for="piggyback_pixels">
                Piggyback Pixels
                <input
                  type="text"
                  name="piggyback_pixels"
                  v-model="piggyback_pixel_input"
                  v-on:keyup.enter="addPixel"
                />
              </label>
            </div>
            <div class="col-xs">
              <v-button :event="addPixel">Add</v-button>
            </div>
          </div>
          <ul class="row middle-xs piggyback_pixels">
            <li
              class="piggyback_pixels"
              v-for="(pixel, id) in advertiser.piggyback_pixels"
              :key="id"
            >
              <font-awesome-icon
                class="remove"
                :icon="['fas', 'minus-circle']"
                @click="removePiggybackPixel(id)"
              ></font-awesome-icon>
              {{ pixel }}
            </li>
          </ul>
        </div>
        <div class="col-xs">
          <div class="row bottom-xs">
            <div class="col-xs-9 p0">
              <label for="authorized_urls">
                Authorized URLs
                <input
                  type="text"
                  name="authorized_urls"
                  v-model="authorized_url_input"
                  v-on:keyup.enter="addAuthorizedUrl"
                />
              </label>
            </div>
            <div class="col-xs">
              <v-button :event="addAuthorizedUrl">Add</v-button>
            </div>
          </div>
          <ul class="row middle-xs authorized_urls">
            <li
              class="authorized_url"
              v-for="(url, id) in advertiser.authorized_urls"
              :key="id"
            >
              <font-awesome-icon
                class="remove"
                :icon="['fas', 'minus-circle']"
                @click="removeAuthorizedUrls(id)"
              ></font-awesome-icon>
              {{ url }}
            </li>
          </ul>
        </div>
      </div>

      <div class="row end-xs">
        <v-button :event="didClickCreateAdvertiser">Create Advertiser</v-button>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      piggyback_pixel_input: null,
      authorized_url_input: null,
      advertiser: {
        apn_name: "",
        apn_adv_id: "",
        active: true,
        name: "",
        start_date: "",
        end_date: "",
        conversion_type: "HomepageConv",
        values_returned: "",
        requires_order_number: false,
        external_file: null,
        order_number_regex: null,
        total_regex: null,
        piggyback_pixels: [],
        authorized_urls: [],
        is_mni: false
      },
      options: [
        { label: "Mid-funnel (Page View)", code: "HomepageConv" },
        { label: "Low-funnel (Conversion, e.g., purchase)", code: "CheckoutConv" },
        { label: "Universal", code: "Universal" }
      ],
      pixelNameError: false
    };
  },
  computed: {
    flip_advertiser() {
      let advertisers = this.$store.state.flip.advertisers;
      return advertisers.length > 0 ? advertisers[0] : {};
    }
  },
  methods: {
    ...mapActions({ createAdvertiser: "flip/createAdvertiser" }),
    addPixel() {
      if (this.piggyback_pixel_input === null) {
        return;
      }
      this.advertiser.piggyback_pixels.push(this.piggyback_pixel_input);
      this.piggyback_pixel_input = null;
    },
    addAuthorizedUrl() {
      if (this.authorized_url_input === null) {
        return;
      }
      this.advertiser.authorized_urls.push(this.authorized_url_input);
      this.authorized_url_input = null;
    },
    async didClickCreateAdvertiser() {
      // let not_required = { ...this.advertiser_not_required };
      // Object.keys(not_required).forEach(key => {
      //   if (
      //     (Array.isArray(not_required[key]) &&
      //       not_required[key].length === 0) ||
      //     not_required[key] === null
      //   ) {
      //     delete not_required[key];
      //   }
      // });

      // validate the name first
      this.pixelNameError = false;
      if (this.advertiser.name) {
        this.advertiser.name = this.advertiser.name.trim();
        if (!/^[a-zA-Z0-9_]+$/.test(this.advertiser.name)) {
          this.pixelNameError = true;
        }
      } else {
        this.pixelNameError = true;
      }
      if (this.pixelNameError) {
        this.$refs.pixel_name.focus();
        return;
      }

      let advertiser = { ...this.advertiser };

      await this.createAdvertiser(advertiser);

      this.$router.push({
        name: "AdvertisersList",
        params: { search: advertiser.name }
      });

      // window.location.href = `/flip/advertisers`;
      // window.location.href = `/flip/advertiser/${this.flip_advertiser.flip_id}/edit`;
    },
    removePiggybackPixel(id) {
      this.advertiser.piggyback_pixels.splice(id, 1);
    },
    removeAuthorizedUrls(id) {
      this.advertiser.authorized_urls.splice(id, 1);
    }
  }
};
</script>
<style lang="scss" scoped>
.remove {
  padding: 0px 10px 2px 10px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  background-color: #d92a36;
  border-radius: 25px;
}
.authorized_urls,
.piggyback_pixels {
  padding: 10px;
}

.info {
  color: gray;
  .chars {
    display: inline !important;
    font-family: "Courier New", Courier, monospace;
    font-weight: bolder;
  }
}
.err {
  border: 2px solid red !important;
  &:focus {
    outline: none !important;
  }
}
</style>
