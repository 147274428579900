<template>
  <div class="container">
    <form v-on:submit.prevent>
      <div class="row">
        <div class="col-xs-4">
          <label for=""
            >Select Account:
            <v-select
              :options="authAccounts"
              :reduce="
                account => ({
                  id: account.id,
                  name: account.title
                })
              "
              label="title"
              @input="setSelected"
            ></v-select>
          </label>
        </div>
      </div>
      <div class="row center-xs">
        <div class="col-xs-4">
          <v-button :event="didClickCreate">Create Account</v-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return { selected: null };
  },
  computed: {
    ...mapState({
      authAccounts: state => state.auth_account.accounts,
      plusAccounts: state => state.adready_plus.accounts
    }),
    plusAccount() {
      return this.plusAccounts.length > 0 ? this.plusAccounts[0] : false;
    }
  },
  async mounted() {
    await this.getAuthAccounts();
  },
  methods: {
    ...mapActions({
      createAccount: "adready_plus/createAccount",
      getAuthAccounts: "auth_account/getAccounts"
    }),
    async didClickCreate() {
      await this.createAccount(this.selected);

      window.location.href = `/adready-plus/account/${this.plusAccount.id}/edit`;
    },
    setSelected({ id, name }) {
      this.selected = { external_account_id: id, name };
    }
  }
};
</script>
