import axios from "axios";

const state = {
  users: [],
  pagination: {}
};

const getters = {};

const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },

  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  }
};
const actions = {
  async getUsers({ commit }, params) {
    await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/users`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      },
      params
    })
      .then(response => {
        commit("SET_USERS", response.data.data);
        let pagination = response.data;
        delete pagination.data;
        commit("SET_PAGINATION", pagination);
      })
      .catch(err =>
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        })
      );
  },
  async getUser({ commit }, user_id) {
    await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/user/${user_id}`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(response => {
        commit("SET_USERS", [response.data]);
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], { root: true });
      });
  },
  async updateUser({ commit }, data) {
    await axios({
      method: "put",
      url: `${process.env.VUE_APP_API_URL}/user/${data.user_id}`,
      headers: { Authorization: `Bearer ${window.Token}` },
      data
    })
      .then(() => {
        commit("SET_MESSAGES", ["User has been updated"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.message], { root: true });
      });
  },
  async createUser({ commit }, user) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/user/`,
      headers: { Authorization: `Bearer ${window.Token}` },
      data: user
    })
      .then(response => {
        commit("SET_MESSAGES", ["User has been created"], { root: true });
        return response.data;
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], { root: true });
      });
  },
  async deleteUser({ commit }, user_id) {
    await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API_URL}/user/${user_id}`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(() => {
        commit("SET_MESSAGES", ["User has been deleted"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.message], { root: true });
      });
  },

  async removeAccountRoleGroup({ commit }, data) {
    await axios({
      method: "put",
      url: `${process.env.VUE_APP_API_URL}/userRoleGroup/remove`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      },
      data
    })
      .then(() => {
        commit("SET_MESSAGES", ["User has been updated"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.message], { root: true });
      });
  },
  async addAccountRoleGroup({ commit }, params) {
    await axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/userRoleGroup/assign`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      },
      data: params
    })
      .then(() => {
        commit("SET_MESSAGES", ["User has been updated"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.message], { root: true });
      });
  },
  async updateAccountRoleGroup({ commit }, data) {
    await axios({
      method: "put",
      url: `${process.env.VUE_APP_API_URL}/userRoleGroup/modify`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      },
      data
    })
      .then(() => {
        commit("SET_MESSAGES", ["User has been updated"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.message], { root: true });
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
