<template>
  <div class="inner-content">
    <div class="row middle-xs between-xs">
      <span class="sub-title">Create Account</span>
    </div>
    <form v-on:submit.prevent class="edit-form">
      <div class="row form-row">
        <div class="col-xs">
          <label for="email">
            Account Name
            <input type="text" v-model="account.title" />
          </label>
        </div>
      </div>
      <div class="row center-xs">
        <div class="col-xs">
          <v-button :event="createAccountAction">Create Account</v-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      account: { title: null, active: true }
    };
  },
  methods: {
    ...mapActions({
      createAccount: "auth_account/createAccount"
    }),
    async createAccountAction() {
      let account = await this.createAccount(this.account);

      if (account) {
        this.$router.push(`/auth/account/${account.id}/edit`);
      }
    }
  }
};
</script>
