<template>
  <div class="inner-content">
    <modals-container v-on:update="updated" />
    <v-dialog />

    <span class="sub-title">Edit User</span>

    <form v-on:submit.prevent class="edit-form">
      <div class="row start-xs form-row info-row">
        <span class="col-xs-3">AdReady ID: {{ user.id }}</span>
        <span class="col-xs-3">
          Status:
          <b :class="status">{{ statusText }}</b>
        </span>
        <span class="col-xs-3">Created At: {{ formatAuthDate(user.createdAt) }}</span>
        <span class="col-xs-3">Updated At: {{ formatAuthDate(user.updatedAt) }}</span>
      </div>

      <div class="row form-row">
        <div class="col-xs">
          <label for="email">
            First Name
            <input type="text" v-model="user_form.firstName" />
          </label>
        </div>
        <div class="col-xs">
          <label for="email">
            Last Name
            <input type="text" v-model="user_form.lastName" />
          </label>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-xs">
          <label for="email">
            Email
            <input type="text" v-model="user_form.email" />
          </label>
        </div>
        <div class="col-xs">
          <label for="email">
            Username
            <input type="text" v-model="user_form.username" />
          </label>
        </div>
      </div>

      <div class="row start-xs">
        <div class="col-xs-3">
          <v-button :event="updateUserAction">Update User Info</v-button>
        </div>
      </div>

      <div class="row start-xs">
        <div class="col-xs-3">
          <v-button :event="showModal">Attach Account</v-button>
        </div>
      </div>

      <div class="row center-xs form-row">
        <div class="card-container" v-for="arg in user.accountRoleGroups" :key="arg.id">
          <div class="row between-xs card-actions">
            <font-awesome-icon
              :icon="['fal', 'times']"
              @click="confirmAccountRoleGroupDelete(arg.id)"
            ></font-awesome-icon>
            <font-awesome-icon
              :icon="['fal', 'pencil']"
              @click="
                showModal({
                  id: arg.id,
                  account_id: arg.account.id,
                  role_id: arg.role.id,
                  group_id: arg.group.id
                })
              "
            ></font-awesome-icon>
          </div>
          <div class="row">
            <div class="col-xs-4">Account:</div>
            <div class="col-xs">{{ arg.account.title }}</div>
          </div>
          <div class="row">
            <div class="col-xs-4">Group:</div>
            <div class="col-xs">{{ arg.group.title }}</div>
          </div>
          <div class="row">
            <div class="col-xs-4">Role:</div>
            <div class="col-xs">{{ arg.role.title }}</div>
          </div>
        </div>
      </div>

      <div class="row center-xs">
        <div class="col-xs">
          <v-button
            :event="updateUserAction"
            :event-data="{ locked: !this.user.locked }"
          >{{ lockButtonText }}</v-button>
        </div>
        <div class="col-xs">
          <v-button :event="confirmUserDelete" v-if="this.status !== 'deleted'">Delete User</v-button>
          <v-button :event="confirmUserRestore" v-if="this.status === 'deleted'">Restore User</v-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import AccountRoleGroupModal from "./../../../components/AccountRoleGroupModal";
import UserMixin from "./../../../mixins/UserMixin";
import { mapActions, mapState } from "vuex";

export default {
  mixins: [UserMixin],
  data() {
    return {
      user_form: {
        firstName: "",
        lastName: "",
        email: "",
        username: ""
      },

      user_id: null
    };
  },
  computed: {
    ...mapState({
      users: state => state.auth_user.users
    }),
    user() {
      return this.users.length > 0 ? this.users[0] : { userInfo: {} };
    },
    status() {
      if (this.user.deleted) {
        return "deleted";
      }
      return this.user.locked ? "locked" : "active";
    },
    statusText() {
      return this.status.charAt(0).toUpperCase() + this.status.slice(1);
    },
    lockButtonText() {
      return this.user.locked ? "Unlock User" : "Lock User";
    }
  },
  created() {
    this.user_id = this.$route.params.id;
  },
  async mounted() {
    await this.getUser(this.user_id);
    this.setUserFormProps();
  },
  methods: {
    ...mapActions({
      getUser: "auth_user/getUser",
      updateUser: "auth_user/updateUser",
      deleteUser: "auth_user/deleteUser",
      removeAccountRoleGroup: "auth_user/removeAccountRoleGroup"
    }),

    showModal({
      id = null,
      account_id = null,
      group_id = null,
      role_id = null
    }) {
      this.$modal.show(
        AccountRoleGroupModal,
        {
          account_id,
          group_id,
          role_id,
          id,

          user_id: this.user.id
        },
        { height: "400px" }
      );
    },
    setUserFormProps() {
      this.user_form = {
        firstName: this.user.userInfo.firstName,
        lastName: this.user.userInfo.lastName,
        email: this.user.email,
        username: this.user.username
      };
    },
    async updateUserAction(params) {
      let updateParams =
        Object.keys(params).length > 0 ? params : this.user_form;

      await this.updateUser({ ...updateParams, user_id: this.user_id });
      await this.updated();
      this.setUserFormProps();
    },
    async updated() {
      await this.getUser(this.user_id);
      this.$modal.hideAll();
    },

    confirmAccountRoleGroupDelete(id) {
      this.$modal.show("dialog", {
        title: "Alert!",
        text: "Are you sure you want to delete this account role group?",
        buttons: [
          {
            title: "Confirm",
            handler: async () => {
              await this.removeAccountRoleGroup({ user_role_group_id: id });
              await this.updated();
              this.$modal.hide("dialog");
            }
          },
          {
            title: "Close"
          }
        ]
      });
    },
    confirmUserDelete() {
      this.showConfirmDialog(async () => {
        await this.deleteUser(this.user_id);
        await this.updated();
        this.$modal.hide("dialog");
      }, "Are you sure you want to delete this user?");
    },
    confirmUserRestore() {
      this.showConfirmDialog(async () => {
        await this.updateUserAction({ deleted: false });
        await this.updated();
        this.$modal.hide("dialog");
      }, "Are you sure you want to restore this user?");
    },
    showConfirmDialog(handler, text) {
      this.$modal.show("dialog", {
        title: "Alert!",
        text,
        buttons: [
          {
            title: "Confirm",
            handler
          },
          {
            title: "Close"
          }
        ]
      });
    }
  }
};
</script>
