import Vue from "vue";

// font-awesome
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faSignOut as falSignOut,
  faTimes as falTimes,
  faPencil as falPencil,
  faExternalLink as falExternalLink,
  faCog as falCog,
  faUsers as falUsers,
  faEye as falEye,
  faEyeSlash as falEyeSlash
} from "@fortawesome/pro-light-svg-icons";

import {
  faArrowLeft as farArrowLeft,
  faArrowRight as farArrowRight
} from "@fortawesome/pro-regular-svg-icons";

import {
  faChartPie as fasChartPie,
  faUser as fasUser,
  faLockAlt as fasLockAlt,
  faLockOpenAlt as fasLockOpenAlt,
  faCheck as fasCheck,
  faPencil as fasPencil,
  faClone as fasClone,
  faBallotCheck as fasBallotCheck,
  faBoxesAlt as fasBoxesAlt,
  faCircleNotch as fasCircleNotch,
  faMinusCircle as fasMinusCircle,
  faEye as fasEye,
  faEyeSlash as fasEyeSlash
} from "@fortawesome/pro-solid-svg-icons";

// configure
library.add(
  falSignOut,
  falTimes,
  fasChartPie,
  falPencil,
  fasUser,
  falUsers,
  farArrowLeft,
  farArrowRight,
  fasLockAlt,
  fasLockOpenAlt,
  falExternalLink,
  falCog,
  fasCheck,
  fasPencil,
  fasClone,
  fasBallotCheck,
  fasBoxesAlt,
  falEyeSlash,
  falEye,
  fasCircleNotch,
  fasMinusCircle,
  fasEye,
  fasEyeSlash
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
