import Vue from "vue";
import Vuex from "vuex";
import authentication from "./modules/authentication";
import user_auth_service from "./modules/auth_service/users";
import account_auth_service from "./modules/auth_service/accounts";
import group_auth_service from "./modules/auth_service/groups";
import role_auth_service from "./modules/auth_service/roles";
import flip_service from "./modules/flip";
import adready_plus_service from "./modules/adready_plus";

Vue.use(Vuex);

const getDefaultState = () => ({
  showLoading: false
});
export default new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    SET_SHOW_LOADING(state, isLoading) {
      state.showLoading = isLoading;
    },
    SET_MESSAGES(state, messages) {
      messages.map(message => {
        Vue.notify({
          title: "Success!",
          text: message,
          type: "success"
        });
      });
    },
    SET_ERRORS(state, errors) {
      errors.map(error => {
        Vue.notify({
          title: "Error",
          text: error,
          type: "error"
        });
      });
    }
  },
  actions: {},
  modules: {
    authentication,
    auth_user: user_auth_service,
    auth_account: account_auth_service,
    auth_group: group_auth_service,
    auth_role: role_auth_service,
    flip: flip_service,
    adready_plus: adready_plus_service
  }
});
