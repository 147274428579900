<template>
  <div class="modal-wrap">
    <div class="row center-xs">
      <div class="col-xs">
        <label for=""
          >Report Type:
          <input type="text" v-model="type" />
        </label>
      </div>
    </div>
    <div class="row center-xs">
      <div class="col-xs">
        <label for=""
          >Report URL:
          <input type="text" v-model="url" />
        </label>
      </div>
    </div>
    <div class="row center-xs">
      <div class="col-xs">
        <label for="">Height: <input type="text" v-model="height" /> </label>
      </div>
    </div>
    <div class="row center-xs">
      <div class="col-xs-5">
        <v-button v-if="create" :event="didClickCreateReport">
          Add Report
        </v-button>
        <v-button v-if="edit" :event="didClickUpdateReport"
          >Update Report</v-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: ["report", "account_id"],

  data() {
    return { type: null, url: null, height: null };
  },
  computed: {
    create() {
      return this.report.type == null && this.report.url == null;
    },
    edit() {
      return this.report.type != null && this.report.url != null;
    }
  },
  mounted() {
    this.type = this.report.type;
    this.url = this.report.url;
    this.height = this.report.height;
  },
  methods: {
    ...mapActions({
      createReport: "adready_plus/createReport",
      updateReport: "adready_plus/updateReport"
    }),
    async didClickCreateReport() {
      await this.createReport({
        type: this.type,
        url: this.url,
        account_id: this.account_id,
        height: this.height
      });
      this.$emit("update");
    },
    async didClickUpdateReport() {
      await this.updateReport({
        report_id: this.report.id,
        data: {
          type: this.type,
          url: this.url,
          account_id: this.account_id,
          height: this.height
        }
      });
      this.$emit("update");
    }
  }
};
</script>
