import axios from "axios";
import jwt_decode from "jwt-decode";

const getDefaultUser = () => ({
  email: null,
  id: null,
  username: null,
  first_name: null,
  last_name: null
});
const getDefaultLogin = () => ({
  email: null,
  password: null
});
const getDefaultState = () => ({
  login: getDefaultLogin(),
  user: getDefaultUser(),
  accounts: [],
  exp: 0,
  iat: 0,
  token: null
});

const state = getDefaultState();
const getters = {
  //TODO::check for valid token as well as null
  isAuthenticated: state =>
    state.token !== null &&
    state.exp >= Math.floor(new Date().getTime() / 1000),
  isTokenExpired: state => state.exp >= Math.floor(new Date().getTime() / 1000),

  token: state => state.token,
  isMasterAdmin: state => {
    let account = state.accounts.filter(
      account =>
        account.name === "Digital Remedy" &&
        account.group.title === "Engineering" &&
        account.role.title === "Admin"
    );
    return account.length > 0;
  },
  canAccessAuth: (state, getters) => {
    return getters.isMasterAdmin;
  },
  canAccessFlip: (state, getters) => {
    if (getters.isMasterAdmin) return getters.isMasterAdmin;

    let account = state.accounts.filter(account =>
      account.role.title.includes("FLIP")
    );
    return account.length > 0;
  },
  flipAuthorization: (state, getters) => {
    if (!getters.canAccessFlip) return [];
    let access = ["read"];

    if (getters.isMasterAdmin) {
      access.push("create", "edit", "delete");
    }

    return access;
  },
  canAccessTPC: (state, getters) => {
    if (getters.isMasterAdmin) return getters.isMasterAdmin;
    let account = state.accounts.filter(account =>
      account.role.title.includes("TPC")
    );
    return account.length > 0;
  },
  canAccessPlus: (state, getters) => {
    if (getters.isMasterAdmin) return getters.isMasterAdmin;
    let account = state.accounts.filter(account =>
      account.role.title.includes("ADREADY+")
    );
    return account.length > 0;
  }
};
const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_ACCOUNTS(state, accounts) {
    state.accounts = accounts;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_PROP(state, { prop, value }) {
    state[prop] = value;
  },
  SET_USER_PROP(state, { prop, value }) {
    state.user[prop] = value;
  },
  SET_AUTHENTICATION_PROP(state, { key, prop, value }) {
    state[key][prop] = value;
  },
  RESET_TOKEN(state) {
    state.token = null;
  },
  RESET_USER(state) {
    state.user = getDefaultUser();
  }
};
const actions = {
  async login({ state, dispatch, commit }) {
    await axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/login`,
      data: {
        email: state.login.email,
        password: state.login.password
      }
    })
      .then(response => {
        dispatch("decodeToken", response.data.response.data.token);
      })
      .catch(error => {
        commit("SET_MESSAGES", [error.response.data.response.message], {
          root: true
        });
      });
  },

  logout({ commit }) {
    return new Promise(resolve => {
      commit("RESET_USER");
      commit("RESET_TOKEN");

      resolve();
    });
  },
  decodeToken({ commit, getters }, token) {
    return new Promise((resolve, reject) => {
      if (getters.isAuthenticated) return resolve();
      if (token === null) return reject();

      let decoded = jwt_decode(token);
      commit("SET_TOKEN", token);
      commit("SET_USER", decoded.user);
      commit("SET_ACCOUNTS", decoded.accounts);
      commit("SET_PROP", { prop: "iat", value: decoded.iat });
      commit("SET_PROP", { prop: "exp", value: decoded.exp });

      localStorage.setItem("token", token);
      resolve();
    });
  },

  async forgotPassword({ commit }, email) {
    await axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/password/forgot`,
      data: {
        email: email
      }
    })
      .then(response => {
        commit("SET_MESSAGES", [response.data.message], { root: true });
      })
      .catch(error => {
        commit("SET_ERRORS", [error.response.data.response.message], {
          root: true
        });
      });
  },
  async resetPassword({ commit }, resetData) {
    await axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/password/reset`,
      data: resetData
    })
      .then(response => {
        commit("SET_MESSAGES", response.data.messages, { root: true });
      })
      .catch(error => {
        commit("SET_ERRORS", [error.response.data.response.message], {
          root: true
        });
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
