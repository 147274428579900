<template>
  <div class="landing-page">
    <TitleBar title="Adready+" image="/assets/img/adready-plus.png" />

    <div class="row center-xs middle-xs">
      <a class="row middle-xs landing-card" href="/adready-plus/accounts">
        <font-awesome-icon :icon="['fas', 'clone']" size="3x"></font-awesome-icon>
        <span>Accounts</span>
      </a>
    </div>
  </div>
</template>

<script>
import TitleBar from "@/components/elements/TitleBar";
export default {
  components: {
    TitleBar
  }
};
</script>
