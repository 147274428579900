import axios from "axios";

const state = {
  accounts: [],
  pagination: {}
};

const getters = {};

const mutations = {
  SET_ACCOUNTS(state, accounts) {
    state.accounts = accounts;
  },

  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  }
};
const actions = {
  async createAccount({ commit }, account) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/account`,
      headers: { Authorization: `Bearer ${window.Token}` },
      data: account
    })
      .then(response => {
        commit("SET_MESSAGES", ["Account has been created"], { root: true });
        return response.data;
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], { root: true });
      });
  },
  async getAccounts({ commit }, params) {
    await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/accounts`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      },
      params
    })
      .then(response => {
        commit("SET_ACCOUNTS", response.data.data);
        let pagination = response.data;
        delete pagination.data;
        commit("SET_PAGINATION", pagination);
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },

  async updateAccount({ commit }, account) {
    await axios({
      method: "put",
      url: `${process.env.VUE_APP_API_URL}/account/${account.id}`,
      headers: { Authorization: `Bearer ${window.Token}` },
      data: account
    })
      .then(() => {
        commit("SET_MESSAGES", ["Account updated"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },

  async getAccount({ commit }, account_id) {
    await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/account/${account_id}`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(response => {
        commit("SET_ACCOUNTS", [response.data]);
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },

  async deleteAccount({ commit }, account_id) {
    await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API_URL}/account/${account_id}`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(() => {
        commit("SET_MESSAGES", ["Account deleted"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
