<template>
  <div class="modal-wrap modal-fields">
    <div class="row between-xs modal-header">
      <span class="sub-title">Account Role Groups</span>
      <div class="close" @click="$emit('close')">
        <font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon>
      </div>
    </div>

    <div class="row start-xs details-row">
      <div class="col-xs">
        <label for="account">
          Account:
          <v-select
            :options="accounts"
            :reduce="account => account.id"
            label="title"
            :value="selected.account"
            @input="setAccount"
          ></v-select>
        </label>
      </div>
    </div>
    <div class="row start-xs details-row">
      <div class="col-xs">
        <label for="group">
          Group:
          <v-select
            :options="groups"
            :reduce="group => group.id"
            label="title"
            :value="selected.group"
            @input="setGroup"
          ></v-select>
        </label>
      </div>
    </div>
    <div class="row start-xs details-row">
      <div class="col-xs">
        <label for="role">
          Role:
          <v-select
            :options="roles"
            :reduce="role => role.id"
            label="title"
            :value="selected.role"
            @input="setRole"
          ></v-select>
        </label>
      </div>
    </div>

    <div class="row center-xs">
      <div class="col-xs-6">
        <v-button :event="addAccountRoleGroupAction" v-if="!showUpdate">Add</v-button>
        <v-button :event="updateAccountRoleGroupAction" v-if="showUpdate">Update</v-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  props: ["id", "account_id", "group_id", "role_id", "user_id"],
  data() {
    return {
      selected: {
        role: null,
        group: null,
        account: null
      }
    };
  },
  computed: {
    ...mapState({
      accounts: state => state.auth_account.accounts,
      roles: state => state.auth_role.roles,
      groups: state => state.auth_group.groups
    }),
    showUpdate() {
      return (
        this.account_id !== null ||
        this.group_id !== null ||
        this.role_id !== null
      );
    }
  },
  async created() {
    if (this.accounts.length === 0) {
      await this.getAccounts();
    }
    if (this.groups.length === 0) {
      await this.getGroups();
    }
    if (this.roles.length === 0) {
      await this.getRoles();
    }
  },
  mounted() {
    this.selected.role = this.role_id;
    this.selected.group = this.group_id;
    this.selected.account = this.account_id;
  },
  methods: {
    ...mapActions({
      getAccounts: "auth_account/getAccounts",
      getRoles: "auth_role/getRoles",
      getGroups: "auth_group/getGroups",
      addAccountRoleGroup: "auth_user/addAccountRoleGroup",
      updateAccountRoleGroup: "auth_user/updateAccountRoleGroup"
    }),
    setAccount(id) {
      this.setSelected("account", id);
    },
    setGroup(id) {
      this.setSelected("group", id);
    },
    setRole(id) {
      this.setSelected("role", id);
    },
    setSelected(type, id) {
      this.selected[type] = id;
    },
    async addAccountRoleGroupAction() {
      await this.addAccountRoleGroup({ ...this.selected, user: this.user_id });
      this.$emit("update");
    },
    async updateAccountRoleGroupAction() {
      await this.updateAccountRoleGroup({
        ...this.selected,
        user_role_group_id: this.id
      });
      this.$emit("update");
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  .title {
    margin-top: 10px;
    margin-bottom: 5px;
  }
}
</style>
