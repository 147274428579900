<template>
  <div class="row middle-xs title-bar">
    <div class="main-logo">
      <img :src="image" :alt="title" />
    </div>
    <div class="title">{{ title }}</div>
  </div>
</template>
<script>
export default {
  name: "TitleBar",
  props: {
    title: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.title-bar {
  padding: 0 0 20px;
  margin: 0 0 30px;
  border-bottom: 1px solid $grey_7;

  .main-logo {
    padding: 0 30px 0 0;
    border-right: 1px solid $grey_7;

    img {
      width: 150px;
      height: auto;
    }
  }
}
</style>
