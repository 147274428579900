<template>
  <div class="modal-wrap">
    <div class="row between-xs modal-header">
      <span class="sub-title">User Details</span>
      <div class="close" @click="$emit('close')">
        <font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon>
      </div>
    </div>

    <div class="row start-xs details-row">
      <div class="col-xs-3">AdReady ID:</div>
      <div class="col-xs">{{ user.id }}</div>
    </div>
    <div class="row start-xs details-row">
      <div class="col-xs-3">Name:</div>
      <div class="col-xs">{{ user.userInfo.firstName }} {{ user.userInfo.lastName }}</div>
    </div>
    <div class="row start-xs details-row">
      <div class="col-xs-3">Email:</div>
      <div class="col-xs">{{ user.email }}</div>
    </div>
    <div class="row start-xs details-row">
      <div class="col-xs-3">Username:</div>
      <div class="col-xs">{{ user.userInfo.username }}</div>
    </div>
    <div class="row start-xs details-row">
      <div class="col-xs-3">Status:</div>
      <div class="col-xs" :class="status">{{ statusText }}</div>
    </div>
    <div class="row start-xs details-row">
      <div class="col-xs-3">Created:</div>
      <div class="col-xs">{{ formatAuthDate(user.createdAt) }}</div>
    </div>
    <div class="row start-xs details-row">
      <div class="col-xs-3">Last Updated:</div>
      <div class="col-xs">{{ formatAuthDate(user.updatedAt) }}</div>
    </div>
    <div class="modal-header accounts">
      <span class="sub-title">Accounts</span>
    </div>
    <div class="details-row">
      <div
        class="row start-xs"
        v-for="arg in user.accountRoleGroups"
        :key="arg.id"
      >{{ generateARGString(arg) }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["user"],
  computed: {
    status() {
      if (this.user.deleted) {
        return "deleted";
      }
      return this.user.locked ? "locked" : "active";
    },
    statusText() {
      return this.status.charAt(0).toUpperCase() + this.status.slice(1);
    }
  },
  methods: {
    generateARGString({ account, role, group }) {
      return `${account.title}: ${role.title} - ${group.title || ""}`;
    }
  }
};
</script>
