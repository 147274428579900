<template>
  <div class="inner-content">
    <div class="row middle-xs between-xs">
      <span class="sub-title">Create Group</span>
    </div>
    <form v-on:submit.prevent class="edit-form">
      <div class="row form-row">
        <div class="col-xs">
          <label for="email">
            Group Name
            <input type="text" v-model="group.title" />
          </label>
        </div>
      </div>
      <div class="row center-xs">
        <div class="col-xs">
          <v-button :event="createGroupAction">Create Group</v-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      group: { title: null, active: true }
    };
  },
  methods: {
    ...mapActions({
      createGroup: "auth_group/createGroup"
    }),
    async createGroupAction() {
      let group = await this.createGroup(this.group);

      if (group) {
        this.$router.push(`/auth/group/${group.id}/edit`);
      }
    }
  }
};
</script>
