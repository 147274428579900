<template>
  <div class="inner-content">
    <div class="row middle-xs between-xs" v-if="canAccess.includes('create')">
      <span class="sub-title">Flip Advertiser List</span>
      <a href="/flip/advertisers/create" class="button solid"
        >Create Advertiser</a
      >
    </div>

    <div class="row middle-xs between-xs sub-header">
      <label for="search" class="search-bar col-xs-9">
        Search:
        <input type="text" v-model="filter.search" />
      </label>
      <label for="status" class="status-bar col-xs-3">
        Status:
        <v-select
          :options="[
            { label: 'All', code: null },
            { label: 'Active', code: true },
            { label: 'Inactive', code: false }
          ]"
          :reduce="option => option.code"
          v-model="filter.active"
        ></v-select>
      </label>
    </div>

    <div class="row center-xs">
      <Loading :show-loading="showLoading" loading-text="Loading data..." />

      <table v-if="!showLoading">
        <tr>
          <th>Name</th>
          <th>Xandr ID</th>
          <th>Xandr Name</th>
          <th class="ct">Conversion Type</th>
          <th class="ac">Active</th>
          <th class="actions">Actions</th>
        </tr>
        <tr v-for="(advertiser, key) in filtered_advertisers" :key="key">
          <td>{{ advertiser.name }}</td>
          <td>{{ advertiser.apn_adv_id }}</td>
          <td>{{ advertiser.apn_name }}</td>
          <td>{{ conversionTypeText(advertiser.conversion_type) }}</td>
          <td>
            <span v-if="advertiser.active">Active</span>
            <span v-if="!advertiser.active">Inactive</span>
          </td>
          <td>
            <div class="row table-actions">
              <div class="col-xs">
                <a :href="`/flip/advertisers/${advertiser.flip_id}/edit`">
                  <font-awesome-icon
                    :icon="['fas', 'pencil']"
                    v-tooltip="'Edit ' + advertiser.name"
                  ></font-awesome-icon>
                </a>
              </div>
              <div class="col-xs">
                <font-awesome-icon
                  :icon="['fas', 'eye']"
                  v-tooltip="'View ' + advertiser.name"
                  @click="showModal(advertiser)"
                ></font-awesome-icon>
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
import ShowModal from "./components/AdvertiserShowModal";
import Loading from "@/components/elements/Loading";

export default {
  props: {
    search: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      filter: { search: "", active: null },
      showLoading: false
    };
  },

  components: { Loading },

  async mounted() {
    if (this.search) {
      this.filter.search = this.search;
    }
    this.showLoading = true;
    await this.getFlipAdvertisers().then(() => {
      this.showLoading = false;
    });
  },

  computed: {
    ...mapState({
      advertisers: state => state.flip.advertisers
    }),
    ...mapGetters({ canAccess: "authentication/flipAuthorization" }),
    filtered_advertisers() {
      let advertisers = Object.values(this.advertisers);

      if (this.filter.active !== null) {
        advertisers = advertisers.filter(
          advertiser => advertiser.active === this.filter.active
        );
      }

      return advertisers.filter(
        advertiser =>
          advertiser.name.toLowerCase().includes(this.filter.search) ||
          (!!advertiser.apn_name &&
            advertiser.apn_name.toLowerCase().includes(this.filter.search)) ||
          String(advertiser.apn_adv_id).includes(this.filter.search)
      );
    }
  },
  methods: {
    ...mapActions({ getFlipAdvertisers: "flip/getAdvertisers" }),
    showModal(advertiser) {
      this.$modal.show(
        ShowModal,
        { advertiser: advertiser },
        { height: "580px" }
      );
    },
    conversionTypeText(conversion_type) {
      if(conversion_type === "HomepageConv"){
        return "Mid-Funnel" ;
      }
      if(conversion_type === "CheckoutConv"){
        return "Low-Funnel" ;
      }
      if(conversion_type === "Universal"){
        return "Universal" ;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ct {
  width: 165px;
}
.ac {
  width: 100px;
}
.actions {
  width: 120px;
}
</style>
