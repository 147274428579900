<template>
  <div class="inner-content">
    <div class="row middle-xs between-xs">
      <span class="sub-title">Create Role</span>
    </div>
    <form v-on:submit.prevent class="edit-form">
      <div class="row form-row">
        <div class="col-xs">
          <label for="email">
            Role Name
            <input type="text" v-model="role.title" />
          </label>
        </div>
      </div>
      <div class="row center-xs">
        <div class="col-xs">
          <v-button :event="createRoleAction">Create Role</v-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      role: { title: null, active: true }
    };
  },
  methods: {
    ...mapActions({
      createRole: "auth_role/createRole"
    }),
    async createRoleAction() {
      let role = await this.createRole(this.role);

      if (role) {
        this.$router.push(`/auth/role/${role.id}/edit`);
      }
    }
  }
};
</script>
