<template>
  <div class="landing-page">
    <TitleBar title="AdReady Flip" image="/assets/img/flip-a.png" />

    <div class="row center-xs middle-xs">
      <a class="row middle-xs landing-card" href="/flip/advertisers">
        <font-awesome-icon :icon="['fal', 'users']" size="3x"></font-awesome-icon>
        <span>Advertisers</span>
      </a>
    </div>
  </div>
</template>

<script>
import TitleBar from "@/components/elements/TitleBar";
export default {
  components: {
    TitleBar
  }
};
</script>
