<template>
  <div class="inner-content">
    <modals-container />

    <div class="row middle-xs between-xs">
      <span class="sub-title">Accounts List</span>
      <a href="/auth/accounts/create" class="button solid btn-5">Create Account</a>
    </div>

    <div class="row middle-xs between-xs sub-header">
      <label for="search" class="search-bar col-xs-9">
        Search:
        <input type="text" v-model="query_params.query" />
      </label>
      <label for="status" class="status-bar col-xs-3">
        Status:
        <v-select
          :options="[
            { label: 'Active', code: true },
            { label: 'Inactive', code: false },
            { label: 'Deleted', model: 'deleted', code: true }
          ]"
          @input="showSelected"
        ></v-select>
      </label>
    </div>

    <div class="row center-xs">
      <Loading :show-loading="showLoading" loading-text="Loading data..." />

      <table v-if="!showLoading">
        <tr>
          <th>Title</th>
          <th class="actions">Actions</th>
        </tr>
        <tr v-for="account in accounts" :key="account.id">
          <td>{{ account.title }}</td>
          <td>
            <div class="row table-actions">
              <div class="col-xs">
                <font-awesome-icon :icon="['fas', 'user']" @click="showModal(account)"></font-awesome-icon>
              </div>
              <div class="col-xs">
                <a :href="`/auth/accounts/${account.id}/edit`">
                  <font-awesome-icon :icon="['fas', 'pencil']"></font-awesome-icon>
                </a>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <Pagination
        v-if="pagination.num_pages > 1"
        @pageChange="updatePage"
        :pagination="pagination"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ShowModal from "./components/ShowModal";
import Pagination from "@/components/Pagination";
import Loading from "@/components/elements/Loading";

export default {
  data() {
    return {
      query_params: {
        page: 1,
        limit: 20,
        query: null,
        sort: "id|asc",
        active: true,
        deleted: false
      },
      showLoading: false
    };
  },
  components: { Pagination, Loading },
  watch: {
    query_params: {
      async handler() {
        await this.getAccounts(this.query_params);
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      accounts: state => state.auth_account.accounts,
      pagination: state => state.auth_account.pagination
    })
  },
  async mounted() {
    this.showLoading = true;
    await this.getAccounts(this.query_params).then(() => {
      this.showLoading = false;
    });
  },
  methods: {
    ...mapActions({
      getAccounts: "auth_account/getAccounts"
    }),
    showSelected(value) {
      if (!!value && value.model === "deleted") {
        return (this.query_params.deleted = value.code);
      }
      return (this.query_params.active = value !== null ? value.code : null);
    },
    showModal(account) {
      this.$modal.show(ShowModal, { account: account }, { height: "300px" });
    },
    updatePage(page) {
      return (this.query_params.page = page);
    }
  }
};
</script>
<style lang="scss" scoped>
.actions {
  width: 160px;
}
</style>
