<template>
  <div class="modal-wrap">
    <div class="row between-xs modal-header">
      <span class="sub-title">Pixel Details</span>
      <div class="close" @click="$emit('close')">
        <font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon>
      </div>
    </div>
    <div class="row start-xs details-row">
      <span class="col-xs-5">Xandr ID:</span>
      {{ advertiser.apn_adv_id }}
    </div>

    <div class="row start-xs details-row">
      <span class="col-xs-5">Flip ID:</span>
      {{ advertiser.flip_id }}
    </div>

    <div class="row start-xs details-row">
      <span class="col-xs-5">Advertiser Name:</span>
      {{ advertiser.name }}
    </div>

    <div class="row start-xs details-row">
      <span class="col-xs-5">Is MNI White Label?</span>
      {{ isWhiteLabel }}
    </div>

    <div class="row start-xs details-row">
      <span class="col-xs-5">AppNexus/Xandr Name:</span>
      {{ advertiser.apn_name }}
    </div>
    <div class="row start-xs details-row">
      <span class="col-xs-5">Average Daily Pixel Fires:</span>
      {{ average_text }}
    </div>
    <div class="row start-xs details-row">
      <span class="col-xs-5">Pixel Fires in last 15 Minutes:</span>
      {{ pixel_fires_text }}
    </div>
    <div class="row start-xs details-row">
      <span class="col-xs-5">Pixel Fires(Yesterday):</span>
      {{ yesterday_text }}
    </div>
    <div class="row start-xs details-row">
      <span class="col-xs-5">Pixel Fires(7 Days Avg.):</span>
      {{ avg_seven_text }}
    </div>
    <div class="row start-xs details-row">
      <span class="col-xs-5">Pixel Fires(Yesterday Delta):</span>
      {{ yesterday_delta_text }}
    </div>
    <div class="row start-xs details-row">
      <span class="col-xs-5">Pixel Fires(7 Days):</span>
      {{ seven_text }}
    </div>

    <div class="row start-xs details-row">
      <span class="col-xs-5">Pixel Fires(30 Days):</span>
      {{ thirty_text }}
    </div>
    <div class="row start-xs details-row">
      <span class="col-xs-5">Pixel Fires(Lifetime):</span>
      {{ lifetime_text }}
    </div>

    <div class="row middle-xs between-xs">
      <div class="col-xs-4">
        <v-button :event="triggerPixelFire">Test Pixel</v-button>
      </div>
      <div
        class="col-xs-3"
        :class="{ success: pixel_fire.success, error: !pixel_fire.success }"
      >
        {{ pixel_fire.text }}
      </div>
      <div class="col-xs-4">
        <copy-pixel :advertiser="advertiser" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import CopyPixel from "./CopyPixel.vue";

export default {
  components: { CopyPixel },

  props: ["advertiser"],

  data() {
    return {
      flip_id: null,
      pixel_fire: { text: "", success: true }
    };
  },

  computed: {
    ...mapState({
      advertisers: state => state.flip.advertisers,
      pixel_fire_count: state => state.flip.pixel_fire_count,
      metrics: state => state.flip.metrics
    }),

    isWhiteLabel() {
      return this.advertiser.is_mni === true ? "Yes" : "No";
    },

    pixel_fires_text() {
      return this.pixel_fire_count !== null
        ? this.numberWithCommas(this.pixel_fire_count)
        : "Loading...";
    },
    average_text() {
      return this.metrics.Avg_Daily_PixelFire !== ""
        ? this.numberWithCommas(this.metrics.Avg_Daily_PixelFire)
        : 0;
    },
    yesterday_text() {
      return this.metrics.Yesterday_ET_PixelFire !== ""
        ? this.numberWithCommas(this.metrics.Yesterday_ET_PixelFire)
        : 0;
    },
    seven_text() {
      return this.metrics.Last_7_Days_ET_PixelFire !== ""
        ? this.numberWithCommas(this.metrics.Last_7_Days_ET_PixelFire)
        : 0;
    },
    thirty_text() {
      return this.metrics.Last_30_Days_ET_PixelFire !== ""
        ? this.numberWithCommas(this.metrics.Last_30_Days_ET_PixelFire)
        : 0;
    },
    lifetime_text() {
      return this.metrics.Lifetime_PixelFire !== ""
        ? this.numberWithCommas(this.metrics.Lifetime_PixelFire)
        : 0;
    },
    avg_seven_text() {
      return this.numberWithCommas(parseInt(this.seven_text / 7)) || 0;
    },
    yesterday_delta_text() {
      return (
        this.numberWithCommas(
          parseInt(this.yesterday_text - this.avg_seven_text)
        ) || 0
      );
    }
  },

  async mounted() {
    await this.getAdvertiserPixelFires(this.advertiser.flip_id);
    await this.getMetrics(this.advertiser.flip_id);
  },

  beforeDestroy() {
    this.$store.commit("flip/SET_METRICS", {});
  },

  methods: {
    ...mapActions({
      getAdvertiserPixelFires: "flip/getAdvertiserPixelFires",
      getMetrics: "flip/getMetrics",
      firePixel: "flip/firePixel"
    }),

    async triggerPixelFire() {
      this.pixel_fire.text = "Firing Pixel";
      this.pixel_fire.success = await this.firePixel(this.advertiser.flip_id);
      this.pixel_fire.text = this.pixel_fire.success
        ? "Pixel Fired!"
        : "Could not fire pixel";
    },

    numberWithCommas(number) {
      if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  }
};
</script>
