<template>
  <div class="section" v-if="access">
    <div class="row between-xs middle-xs section-content">
      <a class="col-xs-9 logo" :href="href">
        <img :src="image" alt />
      </a>
      <div class="col-xs-2 icons p0">
        <div class="row center-xs middle-xs">
          <font-awesome-icon :icon="['fal', 'cog']"></font-awesome-icon>
        </div>
        <div class="row center-xs middle-xs">
          <font-awesome-icon :icon="['fal', 'external-link']"></font-awesome-icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Section",
  props: {
    access: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: ""
    },
    href: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.section {
  padding: 0;
  margin: 0 auto;
  max-width: 250px;
  width: 100%;
  border: 1px solid $grey_7;

  .logo {
    display: inline-block;
    width: 100%;
    padding: 0 0 0 10px;
    a {
      color: $black;
    }
    i {
      color: $primary;
    }
  }

  .section-content {
    margin: 0;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
  }

  .icons {
    border-left: 1px solid $grey_7;

    .row {
      height: 40px;
      &:first-child {
        border-bottom: 1px solid $grey_7;
      }
    }
  }
}
</style>
