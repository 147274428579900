<template>
  <div class="inner-content">
    <div class="row middle-xs between-xs">
      <span class="sub-title">Create User</span>
    </div>

    <form v-on:submit.prevent class="edit-form">
      <div class="row form-row">
        <div class="col-xs">
          <label for="first_name">
            First Name
            <input type="text" v-model="user.first_name" />
          </label>
        </div>
        <div class="col-xs">
          <label for="last_name">
            Last Name
            <input type="text" v-model="user.last_name" />
          </label>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-xs">
          <label for="email">
            Email
            <input type="text" v-model="user.email" />
          </label>
        </div>
        <div class="col-xs">
          <label for="username">
            Username
            <input type="text" v-model="user.username" />
          </label>
        </div>
      </div>

      <div class="row start-xs form-row">
        <div class="col-xs">
          <label for="account">
            Account:
            <v-select
              :options="accounts"
              :reduce="account => account.title"
              label="title"
              v-model="user.account"
            ></v-select>
          </label>
        </div>
      </div>
      <div class="row start-xs form-row">
        <div class="col-xs">
          <label for="group">
            Group:
            <v-select
              :options="groups"
              :reduce="group => group.title"
              label="title"
              v-model="user.group"
            ></v-select>
          </label>
        </div>
      </div>
      <div class="row start-xs form-row">
        <div class="col-xs">
          <label for="role">
            Role:
            <v-select
              :options="roles"
              :reduce="role => role.title"
              label="title"
              v-model="user.role"
            ></v-select>
          </label>
        </div>
      </div>

      <div class="row form-row">
        <div class="col-xs-12">
          <label for>
            Set Password
            <input type="checkbox" v-model="set_password" />
          </label>
        </div>
      </div>

      <div class="row between-xs" v-if="set_password">
        <div class="col-xs">
          <label for="password" class="password">
            Password:
            <input :type="password_input_type" v-model="user.password" autocomplete="off" />
            <font-awesome-icon :icon="show_password_icon" size="2x" @click="toggleHiddenPassword"></font-awesome-icon>
          </label>
        </div>
        <div class="col-xs">
          <label for>
            Confirm Password:
            <input
              :type="password_input_type"
              v-model="user.password_confirm"
              autocomplete="off"
            />
          </label>
        </div>
      </div>
      <div class="row" v-if="set_password">
        <v-button :size="4" :event="generatePassword" :event-data="10">Generate Password</v-button>
      </div>

      <div class="row center-xs">
        <v-button :event="createUserAction">Create User</v-button>
      </div>
    </form>
  </div>
</template>
<script>
import UserMixin from "./../../../mixins/UserMixin";
import { mapActions } from "vuex";
export default {
  mixins: [UserMixin],
  data() {
    return {
      user: {
        first_name: null,
        last_name: null,
        email: null,
        username: null,
        password: null,
        password_confirm: null,
        role: null,
        group: null,
        account: null
      },
      set_password: true,
      password_input_type: "password",
      generated_password: "",
      show_password_icon: ["fal", "eye-slash"],
      LOWER: "abcdefghijklmnopqrstuvwxyz",
      UPPER: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      DIGITS: "1234567890",
      SYMBOLS: "!#%&?+_",
      MAX_LENGTH: 50,
      MIN_LENGTH: 1
    };
  },
  methods: {
    ...mapActions({
      createUser: "auth_user/createUser"
    }),
    async createUserAction() {
      let user = await this.createUser(this.user);
      this.$router.push(`/auth/users/${user.id}/edit`);
    },
    toggleHiddenPassword() {
      if (this.password_input_type === "password") {
        this.password_input_type = "text";
        this.show_password_icon = ["fal", "eye"];
        return;
      }
      this.show_password_icon = ["fal", "eye-slash"];
      this.password_input_type = "password";
    },
    generatePassword(length = 10) {
      let _length = this.adjustLengthWithinLimits(length);
      let _characters = this.secureCharacterCombination();

      this.user.password = this.shufflePassword(
        this.assemblePassword(_characters, _length)
      );
      this.user.password_confirm = this.user.password;
      this.$clipboard(this.password);
      this.$store.commit("SET_MESSAGES", ["Password Copied to Clipboard"]);
    },

    adjustLengthWithinLimits(length) {
      if (!length || length < this.minLength) return this.minLength;
      else if (length > this.maxLength) return this.maxLength;
      else return length;
    },

    secureCharacterCombination() {
      return this.LOWER + this.UPPER + this.DIGITS + this.SYMBOLS;
    },

    assemblePassword(characters, length) {
      let randMax = this.DIGITS.length;
      let randMin = randMax - 4;
      let index = this.random(0, characters.length - 1);
      let password = "";

      for (let i = 0; i < length; i++) {
        let jump = this.random(randMin, randMax);
        index =
          index + jump > characters.length - 1
            ? this.random(0, characters.length - 1)
            : index + jump;
        password += characters[index];
      }

      return password;
    },

    shufflePassword(password) {
      return password
        .split("")
        .sort(() => {
          return 0.5 - Math.random();
        })
        .join("");
    },

    containsAtLeast(string, strings) {
      for (let i = 0; i < strings.length; i++) {
        if (string.indexOf(strings[i]) != -1) return true;
      }
      return false;
    },

    random(min, max) {
      return Math.floor(Math.random() * max + min);
    },

    trim(s) {
      if (typeof String.prototype.trim !== "function")
        return s.replace(/^\s+|\s+$/g, "");
      else return s.trim();
    }
  }
};
</script>
<style lang="scss" scoped>
.password {
  position: relative;
  svg {
    position: absolute;
    right: 10px;
    top: 26px;
  }
}
</style>
