<template>
  <button
    class="button"
    :class="[
      'btn-' + size,
      { outline: outline, solid: !outline, continue: accordionTrigger }
    ]"
    :disabled="disabled"
    type="button"
    @click.prevent="event(eventData)"
  >
    <div class="inner">
      <slot>Button Size {{ size }}</slot>
      <font-awesome-icon
        v-if="showLoading"
        :icon="['fas', 'circle-notch']"
        class="loading"
        pulse
        size="2x"
      ></font-awesome-icon>
    </div>
  </button>
</template>
<script>
export default {
  props: {
    outline: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 6
    },
    event: {
      type: Function,
      default: function() {}
    },
    accordionTrigger: {
      type: Boolean,
      default: true
    },
    eventData: {
      type: [String, Number, Object, Array],
      default: function() {
        return {};
      }
    },

    disabled: {
      type: Boolean,
      default: false
    },
    showLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {}
};
</script>
