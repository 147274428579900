<template>
  <div class="inner-content">
    <modals-container />

    <div class="row middle-xs between-xs">
      <span class="sub-title">Auth Users List</span>
      <a href="/auth/users/create" class="button solid btn-5">Create User</a>
    </div>

    <div class="row middle-xs between-xs sub-header">
      <label for="search" class="search-bar col-xs-9">
        Search:
        <input type="text" v-model="query_params.query" />
      </label>
      <label for="status" class="status-bar col-xs-3">
        Show Users:
        <v-select
          :options="[
            { label: 'All', model: 'all', code: null },
            { label: 'Active', model: 'locked', code: false },
            { label: 'Locked', model: 'locked', code: true },
            { label: 'Deleted', model: 'deleted', code: true }
          ]"
          @input="showSelected"
        ></v-select>
      </label>
    </div>

    <div class="row center-xs">
      <Loading :show-loading="showLoading" loading-text="Loading data..." />

      <table v-if="!showLoading">
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Account Role Group</th>
          <th class="actions">Actions</th>
        </tr>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.userInfo.firstName + " " + user.userInfo.lastName }}</td>
          <td>{{ user.email }}</td>
          <td>
            <ul>
              <li v-for="arg in user.accountRoleGroups" :key="arg.id">{{ generateARGString(arg) }}</li>
            </ul>
          </td>
          <td>
            <div class="row table-actions">
              <div class="col-xs">
                <font-awesome-icon :icon="['fas', 'user']" @click="showModal(user)"></font-awesome-icon>
              </div>
              <div class="col-xs">
                <a :href="`/auth/users/${user.id}/edit`">
                  <font-awesome-icon :icon="['fas', 'pencil']"></font-awesome-icon>
                </a>
              </div>
              <div class="col-xs">
                <span
                  @click="
                    toggleUserLock({ user_id: user.id, locked: !user.locked })
                  "
                >
                  <font-awesome-icon :icon="['fas', 'lock-alt']" v-if="user.locked" class="locked"></font-awesome-icon>
                  <font-awesome-icon
                    :icon="['fas', 'lock-open-alt']"
                    v-if="!user.locked"
                    class="unlocked"
                  ></font-awesome-icon>
                </span>
              </div>
            </div>
          </td>
        </tr>
      </table>

      <Pagination
        v-if="pagination.num_pages > 1"
        @pageChange="updatePage"
        :pagination="pagination"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ShowModal from "./components/ShowModal";
import Pagination from "@/components/Pagination";
import Loading from "@/components/elements/Loading";

export default {
  data() {
    return {
      pages: [],
      query_params: {
        page: 1,
        limit: 20,
        query: null,
        sort: "id|asc",
        deleted: false,
        locked: null
      },
      showLoading: false
    };
  },
  components: { Pagination, Loading },

  watch: {
    query_params: {
      async handler() {
        await this.getUsers(this.query_params);
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      users: state => state.auth_user.users,
      pagination: state => state.auth_user.pagination
    })
  },
  async mounted() {
    this.showLoading = true;
    await this.getUsers(this.query_params).then(() => {
      this.showLoading = false;
    });
  },
  methods: {
    ...mapActions({
      getUsers: "auth_user/getUsers",
      updateUser: "auth_user/updateUser"
    }),
    allUsers() {
      return this.users.length;
    },
    showSelected(value) {
      if (!!value && value.model === "deleted") {
        return (this.query_params.deleted = value.code);
      }
      return (this.query_params.locked = value !== null ? value.code : null);
    },
    showModal(user) {
      this.$modal.show(ShowModal, { user: user }, { height: "450px" });
    },
    generateARGString({ account, role, group }) {
      return `${account.title}: ${role.title} - ${group.title || ""}`;
    },
    updatePage(page) {
      return (this.query_params.page = page);
    },
    async toggleUserLock({ user_id, locked }) {
      await this.updateUser({ user_id, locked });
      await this.getUsers(this.query_params);
    }
  }
};
</script>
<style lang="scss" scoped>
.actions {
  width: 160px;
}
</style>
