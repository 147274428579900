import axios from "axios";

const state = {
  roles: [],
  pagination: {}
};

const getters = {};

const mutations = {
  SET_ROLES(state, roles) {
    state.roles = roles;
  },

  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  }
};
const actions = {
  async createRole({ commit }, role) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/role`,
      headers: { Authorization: `Bearer ${window.Token}` },
      data: role
    })
      .then(response => {
        commit("SET_MESSAGES", ["Role has been created"], { root: true });
        return response.data;
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], { root: true });
      });
  },
  async getRoles({ commit }, params) {
    await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/roles`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      },
      params
    })
      .then(response => {
        commit("SET_ROLES", response.data.data);
        let pagination = response.data;
        delete pagination.data;
        commit("SET_PAGINATION", pagination);
      })
      .catch(err => err);
  },

  async updateRole({ commit }, role) {
    await axios({
      method: "put",
      url: `${process.env.VUE_APP_API_URL}/role/${role.id}`,
      headers: { Authorization: `Bearer ${window.Token}` },
      data: role
    })
      .then(() => {
        commit("SET_MESSAGES", ["Role updated"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },

  async getRole({ commit }, role_id) {
    await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/role/${role_id}`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(response => {
        commit("SET_ROLES", [response.data]);
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },

  async deleteRole({ commit }, role_id) {
    await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API_URL}/role/${role_id}`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(() => {
        commit("SET_MESSAGES", ["Role deleted"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
