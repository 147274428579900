import axios from "axios";

const state = {
  groups: [],
  pagination: {}
};

const getters = {};

const mutations = {
  SET_GROUPS(state, groups) {
    state.groups = groups;
  },

  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  }
};
const actions = {
  async createGroup({ commit }, group) {
    return await axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/group`,
      headers: { Authorization: `Bearer ${window.Token}` },
      data: group
    })
      .then(response => {
        commit("SET_MESSAGES", ["Group has been created"], { root: true });
        return response.data;
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], { root: true });
      });
  },
  async getGroups({ commit }, params) {
    await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/groups`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      },
      params
    })
      .then(response => {
        commit("SET_GROUPS", response.data.data);
        let pagination = response.data;
        delete pagination.data;
        commit("SET_PAGINATION", pagination);
      })
      .catch(err => err);
  },

  async updateGroup({ commit }, group) {
    await axios({
      method: "put",
      url: `${process.env.VUE_APP_API_URL}/group/${group.id}`,
      headers: { Authorization: `Bearer ${window.Token}` },
      data: group
    })
      .then(() => {
        commit("SET_MESSAGES", ["Group updated"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },

  async getGroup({ commit }, group_id) {
    await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/group/${group_id}`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(response => {
        commit("SET_GROUPS", [response.data]);
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },

  async deleteGroup({ commit }, group_id) {
    await axios({
      method: "delete",
      url: `${process.env.VUE_APP_API_URL}/group/${group_id}`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(() => {
        commit("SET_MESSAGES", ["Group deleted"], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
