<template>
  <div class="modal-wrap">
    <div class="row between-xs modal-header">
      <span class="sub-title">Group Details</span>
      <div class="close" @click="$emit('close')">
        <font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon>
      </div>
    </div>

    <div class="row start-xs details-row">
      <div class="col-xs-3">AdReady ID:</div>
      <div class="col-xs">{{ group.id }}</div>
    </div>
    <div class="row start-xs details-row">
      <div class="col-xs-3">AdReady Name:</div>
      <div class="col-xs">{{ group.title }}</div>
    </div>
    <div class="row start-xs details-row">
      <div class="col-xs-3">Status:</div>
      <div class="col-xs" :class="status">{{ statusText }}</div>
    </div>
    <div class="row start-xs details-row">
      <div class="col-xs-3">Created:</div>
      <div class="col-xs">{{ formatAuthDate(group.createdAt) }}</div>
    </div>
    <div class="row start-xs details-row">
      <div class="col-xs-3">Last Updated:</div>
      <div class="col-xs">{{ formatAuthDate(group.updatedAt) }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["group"],
  computed: {
    statusText() {
      return this.status.charAt(0).toUpperCase() + this.status.slice(1);
    },
    status() {
      if (this.group.deletedAt != null) {
        return "deleted";
      }
      return this.group.active ? "active" : "deleted";
    }
  },
  methods: {}
};
</script>
