import Vue from "vue";
import App from "./App.vue";
import VButton from "./components/elements/Button";
import Section from "./components/elements/Section";
import TitleBar from "./components/elements/TitleBar";
import Loading from "./components/elements/Loading";
import Pagination from "./components/Pagination";
import vmodal from "vue-js-modal";
import router from "./router";
import "./utils/fonts";
import store from "./store";
import axios from "axios";
import VSelect from "vue-select";
import Clipboard from "v-clipboard";
import Notifications from "vue-notification";
import VTooltip from "v-tooltip";

Vue.config.productionTip = false;

Vue.prototype.$http = axios;
let token = localStorage.getItem("token");

Vue.prototype.$http.interceptors.request.use(
  config => {
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  error => {
    return Promise.reject(error);
  }
);

router.beforeEach((to, from, next) => {
  if (!to.matched.some(record => record.meta.requiresAuth)) {
    next();
  } else {
    let isAuthenticated = store.getters["authentication/isAuthenticated"];

    //if the user is not authenticated but a token was fetched from localstorage
    //save the token to the store and get the value of isAuthenticated again
    if (!isAuthenticated && token !== null) {
      store.dispatch("authentication/decodeToken", token);
      isAuthenticated = store.getters["authentication/isAuthenticated"];
    }
    if (!isAuthenticated) {
      next("/login");
      return;
    }

    next();
  }
});

Vue.component("VButton", VButton);
Vue.component("Section", Section);
Vue.component("TitleBar", TitleBar);
Vue.component("Loading", Loading);
Vue.component("Pagination", Pagination);
Vue.component("VSelect", VSelect);

Vue.use(vmodal, { dynamic: true, injectModalsContainer: true, dialog: true });
Vue.use(Clipboard);
Vue.use(Notifications);
Vue.use(VTooltip);

Vue.mixin({
  methods: {
    formatAuthDate: dateString => {
      let date = new Date(dateString);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
