<template>
  <div class="inner-content">
    <modals-container v-on:update="updated" />
    <v-dialog />

    <span class="sub-title">Edit Account</span>

    <form v-on:submit.prevent class="edit-form">
      <div class="row start-xs form-row info-row">
        <span class="col-xs-3">AdReady ID: {{ account.id }}</span>
        <span class="col-xs-3">
          Status:
          <b :class="status">{{ statusText }}</b>
        </span>
        <span class="col-xs-3">Created At: {{ formatAuthDate(account.createdAt) }}</span>
        <span class="col-xs-3">Updated At: {{ formatAuthDate(account.updatedAt) }}</span>
      </div>

      <div class="row form-row">
        <div class="col-xs">
          <label for="email">
            Account Name
            <input type="text" v-model="account_form.title" />
          </label>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-xs">
          <v-button :event="updateAccountAction">Update Account</v-button>
        </div>
      </div>

      <div class="row center-xs form-row">
        <div class="col-xs">
          <v-button :event="confirmAccountDelete" v-if="this.deleted === null">Delete Account</v-button>
          <v-button :event="confirmAccountRestore" v-if="this.deleted !== null">Restore Account</v-button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return { account_id: null, account_form: { title: null, active: false } };
  },
  computed: {
    ...mapState({
      accounts: state => state.auth_account.accounts
    }),
    account() {
      return this.accounts.length > 0 ? this.accounts[0] : { userInfo: {} };
    },
    deleted() {
      return this.account.deletedAt != null ? "deleted" : null;
    },
    statusText() {
      return this.status.charAt(0).toUpperCase() + this.status.slice(1);
    },
    status() {
      if (this.account.deletedAt != null) {
        return "deleted";
      }
      return this.account.active ? "active" : "deleted";
    }
  },
  created() {
    this.account_id = this.$route.params.id;
  },
  async mounted() {
    await this.getAccount(this.account_id);
    this.setAccountFormProps();
  },

  methods: {
    ...mapActions({
      getAccount: "auth_account/getAccount",
      updateAccount: "auth_account/updateAccount",
      deleteAccount: "auth_account/deleteAccount"
    }),
    setAccountFormProps() {
      this.account_form = {
        title: this.account.title,
        active: this.account.active
      };
    },
    async updateAccountAction(params) {
      let updateParams =
        Object.keys(params).length > 0 ? params : this.account_form;

      await this.updateAccount({
        ...updateParams,
        id: this.account_id
      });
      await this.updated();
    },
    async updated() {
      await this.getAccount(this.account_id);
      this.setAccountFormProps();
    },
    confirmAccountDelete() {
      this.showConfirmDialog(async () => {
        await this.deleteAccount(this.account_id);
        this.updated();
        this.$modal.hide("dialog");
      }, "Are you sure you want to delete this account?");
    },
    confirmAccountRestore() {
      this.showConfirmDialog(async () => {
        await this.updateAccount({
          id: this.account_id,
          active: true,
          deletedAt: null
        });
        this.updated();
        this.$modal.hide("dialog");
      }, "Are you sure you want to restore this account?");
    },
    showConfirmDialog(handler, text) {
      this.$modal.show("dialog", {
        title: "Alert!",
        text,
        buttons: [
          {
            title: "Confirm",
            handler
          },
          {
            title: "Close"
          }
        ]
      });
    }
  }
};
</script>
