<template>
  <div class="inner-content">
    <span class="sub-title">Edit Flip Advertiser</span>

    <form v-on:submit.prevent class="edit-form">
      <div class="row form-row">
        <div class="col-xs">
          <label for>
            Xandr Name
            <input type="text" v-model="advertiser_clone.apn_name" />
          </label>
        </div>
        <div class="col-xs">
          <label for>
            Pixel Name
            <input
              type="text"
              ref="pixel_name"
              :class="{ err: pixelNameError }"
              v-model="advertiser_clone.name"
            />
            <span class="info">
              Allowed characters:
              <span class="chars">A-Z a-z 0-9 _</span> (alphanumeric and
              underscore only)
            </span>
          </label>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-xs">
          <label for>
            Xandr ID
            <input type="text" v-model="advertiser_clone.apn_adv_id" />
          </label>
        </div>
        <div class="col-xs">
          <label for>
            Conversion Type:
            <v-select
              :options="options"
              :reduce="option => option.code"
              v-model="advertiser_clone.conversion_type"
            ></v-select>
          </label>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-xs">
          <label for="order_number_regex">
            Order Number Regex:
            <input
              type="text"
              name="order_number_regex"
              v-model="advertiser_clone.order_number_regex"
            />
          </label>
        </div>
        <div class="col-xs">
          <label for="total_regex">
            Total Regex:
            <input
              type="text"
              name="total_regex"
              v-model="advertiser_clone.total_regex"
            />
          </label>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-xs-6">
          <label for="external_file">
            External File:
            <input
              type="text"
              name="external_file"
              v-model="advertiser_clone.external_file"
            />
          </label>
        </div>
        <div class="col-xs">
          <div class="row">
            <div class="col-xs">
              <label>
                Requires Order Number:
                <input
                  type="checkbox"
                  v-model="advertiser_clone.requires_order_number"
                />
              </label>
            </div>
            <div class="col-xs">
              <label>
                Is MNI White-label:
                <input type="checkbox" v-model="advertiser_clone.is_mni" />
              </label>
            </div>
            <div class="col-xs">
              <label>
                Active:
                <input type="checkbox" v-model="advertiser_clone.active" />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-xs">
          <div class="row bottom-xs">
            <div class="col-xs-9 p0">
              <label for="piggyback_pixels">
                Piggyback Pixels
                <input
                  type="text"
                  name="piggyback_pixels"
                  v-model="piggyback_pixel_input"
                  v-on:keyup.enter="addPixel"
                />
              </label>
            </div>
            <div class="col-xs">
              <v-button :event="addPixel">Add</v-button>
            </div>
          </div>
          <ul
            class="row start-xs piggyback_pixels"
            v-if="advertiser_clone.piggyback_pixels"
          >
            <li
              class="piggyback_pixel"
              v-for="(pixel, id) in advertiser_clone.piggyback_pixels"
              :key="id"
            >
              <font-awesome-icon
                class="remove"
                :icon="['fas', 'minus-circle']"
                @click="removePiggybackPixel(id)"
              ></font-awesome-icon>
              {{ pixel }}
            </li>
          </ul>
        </div>
        <div class="col-xs">
          <div class="row bottom-xs">
            <div class="col-xs-9 p0">
              <label for="authorized_urls">
                Authorized URLs
                <input
                  type="text"
                  name="authorized_urls"
                  v-model="authorized_url_input"
                  v-on:keyup.enter="addAuthorizedUrl"
                />
              </label>
            </div>
            <div class="col-xs">
              <v-button :event="addAuthorizedUrl">Add</v-button>
            </div>
          </div>
          <ul
            class="row start-xs authorized_urls"
            v-if="advertiser_clone.authorized_urls"
          >
            <li
              class="authorized_url"
              v-for="(url, id) in advertiser_clone.authorized_urls"
              :key="id"
            >
              <font-awesome-icon
                class="remove"
                :icon="['fas', 'minus-circle']"
                @click="removeAuthorizedUrls(id)"
              ></font-awesome-icon>
              {{ url }}
            </li>
          </ul>
        </div>
      </div>

      <div class="row middle-xs end-xs">
        <div class="col-xs start-xs">
          <copy-pixel :advertiser="advertiser_clone" />
        </div>
        <div class="col-xs">
          <Loading :show-loading="showLoading" :smallLoader="true" />
          <v-button :event="editAdvertiserClick" :event-data="advertiser_clone"
            >Update Advertiser</v-button
          >
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import Loading from "@/components/elements/Loading";
import CopyPixel from "./components/CopyPixel";

export default {
  data() {
    return {
      advertiser_id: null,
      advertiser_clone: {},
      piggyback_pixel_input: null,
      authorized_url_input: null,
      options: [
        { label: "Mid-funnel (Page View)", code: "HomepageConv" },
        {
          label: "Low-funnel (Conversion, e.g., purchase)",
          code: "CheckoutConv"
        },
        { label: "Universal", code: "Universal" }
      ],
      showLoading: false,
      pixelNameError: false
    };
  },

  components: { Loading, CopyPixel },

  computed: {
    ...mapState({
      advertisers: state => state.flip.advertisers
    }),
    advertiser() {
      return this.advertisers.length > 0 ? this.advertisers[0] : {};
    }
  },

  async created() {
    this.advertiser_id = this.$route.params.id;
    await this.getAdvertiser(this.advertiser_id);
    this.advertiser_clone = {
      ...this.advertiser
    };
  },

  methods: {
    ...mapActions({
      getAdvertiser: "flip/getAdvertiser",
      editAdvertiser: "flip/editAdvertiser"
    }),
    async editAdvertiserClick() {
      // validate the name first
      this.pixelNameError = false;
      if (this.advertiser_clone.name) {
        this.advertiser_clone.name = this.advertiser_clone.name.trim();
        if (!/^[a-zA-Z0-9_]+$/.test(this.advertiser_clone.name)) {
          this.pixelNameError = true;
        }
      } else {
        this.pixelNameError = true;
      }
      if (this.advertiser_clone.name === this.advertiser.name) {
        this.pixelNameError = false;
      }
      if (this.pixelNameError) {
        this.$refs.pixel_name.focus();
        return;
      }

      this.showLoading = true;
      await this.editAdvertiser(this.advertiser_clone).then(() => {
        this.showLoading = false;
      });
    },
    addPixel() {
      if (this.piggyback_pixel_input === null) {
        return;
      }
      if (
        !Object.prototype.hasOwnProperty.call(
          this.advertiser_clone,
          "piggyback_pixels"
        )
      ) {
        this.advertiser_clone.piggyback_pixels = [];
      }
      this.advertiser_clone.piggyback_pixels.push(this.piggyback_pixel_input);
      this.piggyback_pixel_input = null;
    },
    addAuthorizedUrl() {
      if (this.authorized_url_input === null) {
        return;
      }
      if (
        !Object.prototype.hasOwnProperty.call(
          this.advertiser_clone,
          "authorized_urls"
        )
      ) {
        this.advertiser_clone.authorized_urls = [];
      }
      this.advertiser_clone.authorized_urls.push(this.authorized_url_input);
      this.authorized_url_input = null;
    },
    removePiggybackPixel(id) {
      this.advertiser_clone.piggyback_pixels.splice(id, 1);
    },
    removeAuthorizedUrls(id) {
      this.advertiser_clone.authorized_urls.splice(id, 1);
    }
  }
};
</script>
<style lang="scss" scoped>
.remove {
  padding: 0;
  margin: 0 5px 0 0;
  font-size: 20px;
  color: $alert;
  cursor: pointer;
}
.piggyback_pixels,
.authorized_urls {
  flex-direction: column;
  padding: 20px 0 0;

  li {
    padding: 0 0 10px;
  }
}

.info {
  color: gray;
  .chars {
    display: inline !important;
    font-family: "Courier New", Courier, monospace;
    font-weight: bolder;
  }
}
.err {
  border: 2px solid red !important;
  &:focus {
    outline: none !important;
  }
}
</style>
