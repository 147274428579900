<template>
  <button
    class="button solid"
    v-clipboard="() => this.pixelScript"
    @click="onClickCopy"
  >
    {{ copyText }}
  </button>
</template>

<script>
const DEFAULT_COPY_TEXT = "Copy Pixel Script";

export default {
  props: ["advertiser"],

  data() {
    return {
      copyText: DEFAULT_COPY_TEXT
    };
  },

  computed: {
    pixelHost() {
      return this.advertiser.is_mni === true
        ? "tracker.mnixdata.com"
        : "tracker.adreadyclick.com";
    },

    pixelLibName() {
      return this.advertiser.is_mni === true ? "FLPIXEL" : "ADREADY";
    },

    pixelFileName() {
      return this.advertiser.is_mni === true ? "pixel.js" : "flip.js";
    },

    pixelScript() {
      let script =
        // split up closing /script tag to fix bug in vue template parser
        `<script src='https://${this.pixelHost}/${this.pixelFileName}'></` +
        "script>" +
        "\n<script>\n";

      if (this.advertiser.conversion_type === "CheckoutConv") {
        script += `${this.pixelLibName}.fire("${this.advertiser.flip_id}", {
  "orderNumber": "<ORDER_NUMBER>",
  "amount": <ORDER_AMOUNT>,
});`;
      } else {
        script += `${this.pixelLibName}.fire("${this.advertiser.flip_id}");`;
      }

      return script + `\n</` + `script>`;
    }
  },

  methods: {
    onClickCopy() {
      this.copyText = "Copied";
      setTimeout(() => {
        this.copyText = DEFAULT_COPY_TEXT;
      }, 750);
    }
  }
};
</script>
