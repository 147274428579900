import Vue from "vue";
import VueRouter from "vue-router";

import Login from "../pages/Login.vue";
import Logout from "../pages/Logout.vue";
import ForgotPassword from "../pages/ForgotPassword.vue";
import ResetPassword from "../pages/ResetPassword.vue";

import Home from "../pages/Home.vue";
import AuthService from "../pages/Landing/AuthService.vue";
import FlipService from "../pages/Landing/FlipService.vue";
import AdReadyPlusService from "../pages/Landing/AdReadyPlusService.vue";

import LandingTemplate from "../templates/LandingTemplate.vue";

import UserList from "../pages/AuthService/Users/List.vue";
import UserEdit from "../pages/AuthService/Users/Edit.vue";
import UserCreate from "../pages/AuthService/Users/Create.vue";

import AccountList from "../pages/AuthService/Accounts/List.vue";
import AccountCreate from "../pages/AuthService/Accounts/Edit.vue";
import AccountEdit from "../pages/AuthService/Accounts/Create.vue";

import RoleList from "../pages/AuthService/Roles/List.vue";
import RoleCreate from "../pages/AuthService/Roles/Edit.vue";
import RoleEdit from "../pages/AuthService/Roles/Create.vue";

import GroupList from "../pages/AuthService/Groups/List.vue";
import GroupCreate from "../pages/AuthService/Groups/Edit.vue";
import GroupEdit from "../pages/AuthService/Groups/Create.vue";

import FlipAdvertiserList from "../pages/FlipService/AdvertisersList.vue";
import FlipAdvertiserCreate from "../pages/FlipService/AdvertiserCreate.vue";
import FlipAdvertiserEdit from "../pages/FlipService/AdvertiserEdit.vue";

import AdReadyPlusAccountList from "../pages/AdReadyPlusService/AccountList.vue";
import AdReadyPlusAccountEdit from "../pages/AdReadyPlusService/AccountEdit.vue";
import AdReadyPlusAccountCreate from "../pages/AdReadyPlusService/AccountCreate.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/login", name: "login", component: Login },
  { path: "/logout", name: "logout", component: Logout },
  { path: "/forgot", name: "forgot", component: ForgotPassword },
  {
    path: "/password/reset/:email/:token",
    name: "reset",
    component: ResetPassword
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: "/auth",
    component: LandingTemplate,
    meta: { requiresAuth: true },
    children: [
      { path: "/", component: AuthService },
      {
        path: "users",
        component: UserList
      },
      {
        path: "users/create",
        component: UserCreate
      },
      {
        path: "users/:id/edit",
        component: UserEdit
      },
      {
        path: "accounts",
        component: AccountList
      },
      {
        path: "accounts/:id/edit",
        component: AccountCreate
      },
      {
        path: "accounts/create",
        component: AccountEdit
      },
      {
        path: "roles",
        component: RoleList
      },
      {
        path: "roles/:id/edit",
        component: RoleCreate
      },
      {
        path: "roles/create",
        component: RoleEdit
      },
      {
        path: "groups",
        component: GroupList
      },
      {
        path: "groups/:id/edit",
        component: GroupCreate
      },
      {
        path: "groups/create",
        component: GroupEdit
      }
    ]
  },

  {
    path: "/flip",
    component: LandingTemplate,
    meta: { requiresAuth: true },
    children: [
      { path: "/", component: FlipService },
      {
        name: "AdvertisersList",
        path: "advertisers",
        component: FlipAdvertiserList,
        props: true
      },
      {
        path: "advertisers/create",
        component: FlipAdvertiserCreate
      },
      {
        path: "advertisers/:id/edit",
        component: FlipAdvertiserEdit
      }
    ]
  },

  {
    path: "/adready-plus",
    component: LandingTemplate,
    meta: { requiresAuth: true },
    children: [
      { path: "/", component: AdReadyPlusService },
      {
        path: "accounts",
        component: AdReadyPlusAccountList
      },
      {
        path: "accounts/:id/edit",
        component: AdReadyPlusAccountEdit
      },
      {
        path: "accounts/create",
        component: AdReadyPlusAccountCreate
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
