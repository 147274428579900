import axios from "axios";

const state = {
  advertisers: [],
  pixel_fire_count: null,
  metrics: {},
  pagination: {}
};

const getters = {};

const mutations = {
  SET_ADVERTISERS(state, advertisers) {
    state.advertisers = advertisers;
  },
  SET_PIXEL_FIRE_COUNT(state, count) {
    state.pixel_fire_count = count;
  },
  SET_METRICS(state, metrics) {
    state.metrics = metrics;
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  }
};
const actions = {
  async getAdvertisers({ commit }) {
    await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/flip/advertisers`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(response => {
        commit("SET_ADVERTISERS", response.data);
        let total = response.data.length;
        let limit = 20;
        let num_pages = Math.ceil(total / limit || 1);
        let current_page = 1;
        let from = current_page === 1 ? 1 : limit * current_page - limit + 1;
        let to = from + limit - 1;

        commit("SET_PAGINATION", {
          total,
          limit,
          num_pages,
          current_page,
          from,
          to
        });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },
  async getAdvertiser({ commit }, flip_id) {
    await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/flip/advertiser/${flip_id}`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(response => {
        commit("SET_ADVERTISERS", [response.data.advertiser]);
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },
  async getAdvertiserPixelFires({ commit }, flip_id) {
    await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/flip/advertiser/${flip_id}/pixel-fires`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(response => {
        commit("SET_PIXEL_FIRE_COUNT", response.data.pixel_fire_count);
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },
  async firePixel({ commit }, flip_id) {
    return await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/flip/advertiser/${flip_id}/fire`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(response => {
        commit("SET_MESSAGES", [response.data], { root: true });
        return true;
      })
      .catch(err => {
        commit("SET_ERRORS", err.response.data, {
          root: true
        });
        return false;
      });
  },
  async createAdvertiser({ commit }, advertiser) {
    await axios({
      method: "post",
      url: `${process.env.VUE_APP_API_URL}/flip/advertiser`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      },
      data: advertiser
    })
      .then(response => {
        commit("SET_ADVERTISERS", [response.data.advertiser]);
        commit("SET_MESSAGES", [response.data.message], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },
  async editAdvertiser({ commit }, advertiser) {
    await axios({
      method: "put",
      url: `${process.env.VUE_APP_API_URL}/flip/advertiser/${advertiser.flip_id}`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      },
      data: advertiser
    })
      .then(response => {
        commit("SET_ADVERTISERS", [response.data.advertiser]);
        commit("SET_MESSAGES", [response.data.message], { root: true });
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  },
  async getMetrics({ commit }, flip_id) {
    await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URL}/flip/advertiser/${flip_id}/metrics`,
      headers: {
        Authorization: `Bearer ${window.Token}`
      }
    })
      .then(response => {
        commit("SET_METRICS", response.data.pixel_fire_metrics);
      })
      .catch(err => {
        commit("SET_ERRORS", [err.response.data.message], {
          root: true
        });
      });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
