<template>
  <div class="login-wrap">
    <div class="row start-xs top-bar">
      <a href="/">
        <img :src="`/assets/img/logo-small.webp`" class="main-logo" />
      </a>
    </div>
    <div class="row center-xs auth-form">
      <div class="col-xs-7 p0">
        <img :src="`/assets/img/rocket-dark.webp`" />
      </div>
      <div class="col-xs edit-form">
        <div class="row form-row">
          <label for="email">
            Email
            <input type="email" name="email" @input="setLoginProp" />
          </label>
        </div>
        <div class="row form-row">
          <label for="password">
            Password
            <input type="password" name="password" @input="setLoginProp" />
          </label>
        </div>
        <div class="row center-xs login-btn">
          <v-button
            :outline="false"
            :size="6"
            :event="submitLogin"
            :show-loading="showLoading"
            :disabled="disabled"
          >Login</v-button>
        </div>
        <div class="row end-xs">
          <a href="/forgot">Forgot Password</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      showLoading: false,
      disabled: false
    };
  },
  computed: {
    ...mapState({
      email: state => state.authentication.login.email,
      password: state => state.authentication.login.password
    }),
    ...mapGetters({
      isAuthenticated: "authentication/isAuthenticated"
    })
  },
  watch: {
    isAuthenticated(val) {
      if (val) return this.$router.push("/");
    }
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push("/");
    }
  },
  methods: {
    ...mapMutations({
      setAuthProp: "authentication/SET_AUTHENTICATION_PROP"
    }),
    ...mapActions({
      login: "authentication/login"
    }),
    setLoginProp($event) {
      this.setAuthProp({
        key: "login",
        prop: $event.target.name,
        value: $event.target.value
      });
    },
    async submitLogin() {
      this.showLoading = true;
      this.disabled = true;
      await this.login();
      this.showLoading = false;
      this.disabled = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.login-wrap {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  height: 100vh;

  .main-logo {
    width: 180px;
    margin: 40px;
  }
}
.auth-form {
  max-width: 700px;
  padding: 0;
  margin: 60px auto;
  background-color: #191818;

  .edit-form {
    padding: 15px 15px 0;
  }

  label {
    color: #9c9c9c;
  }
  a {
    color: $white;
    font-size: 0.8em;
  }
  .login-btn {
    padding: 20px 0;

    button {
      margin: 0;
      max-width: 100%;
    }
  }
}
</style>
